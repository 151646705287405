import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { withRouter } from "react-router-dom";
import { Row, Col, Table } from 'reactstrap'
import { translate } from "../../../../utils/translate";
import "../../../../css/home.scss";
import { api } from "../../../../utils/api";
import { formatNumber } from "../../../../utils/function";
import NoResultFound from "../../Common/NoResultFound";
import Loadingv3 from "../../../components/Loading/Loadingv4";
import { logout } from "../../../../store/actions/AuthActions";

const Withdrawal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const productPerpage = 10;
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [historyList, setHistoryList] = useState([]);
    const [withdrawInfo, setWithdrawInfo] = useState(null);

    useEffect(() => {
        getInfo();
        // getWithdraw();
    }, [props.auth.auth.token, pageNumber])

    const getInfo = () => {
        setLoading(true)
        fetch(api.myBill + `?pageNo=${pageNumber}&pageSize=${productPerpage}&walletType=USDT&transactionType=WITHDRAW`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setResult(result)
                    setHistoryList(result.transactionHistoryList.walletTrxDtoList);
                    setTotalRecord(result.transactionHistoryList.totalRecords);
                    let totalPage = Math.ceil(result.transactionHistoryList.totalRecords / productPerpage);
                    setPageCount(totalPage);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    // const getWithdraw = () => {
    //     setLoading(true)
    //     fetch(api.myBill + `?pageNo=1&pageSize=10&walletType=USDT&transactionType=WITHDRAW`, {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json",
    //             "Content-Language": props.lang.toLowerCase(),
    //             "Authorization": "Bearer " + props.auth.auth.token
    //         }),
    //     })
    //         .then(response => Promise.all([response.status, response.text()]))
    //         // .then(response => Promise.all([response.ok, response.text()]))
    //         .then(responseJson => {
    //             setLoading(false);
    //             let result = JSON.parse(responseJson[1]);
    //             if (responseJson[0] === 200) {
    //                 console.log('result', result)
    //                 setWithdrawInfo(result)
    //             } else if (responseJson[0] === 401) {
    //                 dispatch(logout(props.history, 'login'));
    //             } else {
    //                 swal(translate(props.lang, 'Error'), result.message, "error");
    //             }
    //         }).catch(error => {
    //             console.error("error", error);
    //         });
    // }

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    return (
        <>
            <div className="home withdrawal">
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Total Withdrawal")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">
                                        <span className={`font-weight-500 font-35 text-white letter-spacing-05px`}>{result ? formatNumber(result.totalTransactionType) : 0}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Withdrawal")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            {loading ? (<Loadingv3 />) : (
                                <Table responsive className="w-100">
                                    <thead>
                                        <tr>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Reference no")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Time")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Withdrawal")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList.map((item, i) => (
                                            <tr key={i}>
                                                <td className={`font-weight-500 font-13 letter-spacing-05px text-capitalize`}>{item.description}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.transactionDate}</td>
                                                <td className={`font-weight-600 font-13 letter-spacing-05px primary-text`}>{item.amountString}</td>
                                            </tr>
                                        ))}
                                        {totalRecord === 0 && !loading ? (
                                            <tr>
                                                <td colspan={3}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                        {totalRecord > 0 ? (
                            <div>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                                    previousLinkClassName={"pagination__previouslink text-blue-1"}
                                    nextLinkClassName={"pagination__nextlink text-blue-1"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Withdrawal));