import { useState, useEffect } from "react";
import swal from "sweetalert";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Input } from 'reactstrap';
import { Link, useHistory, withRouter } from "react-router-dom";
import { FaChevronLeft, FaSpinner } from "react-icons/fa";
import { translate } from "../../../../utils/translate";
import "../../../../css/home.scss";
import { api } from "../../../../utils/api";
import { logout } from "../../../../store/actions/AuthActions";

const WithdrawRequest = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [withdrawalTypeFromList, setWithdrwalTypeFromList] = useState([]);
    const [withdrawalTypeToList, setWithdrawalTypeToList] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [balanceAmount, setBalanceAmount] = useState(0);
    const errorsObj = { withdrawMethod: '', withdrawTo: '', walletAddress: '', amount: '', transactionPassword: '' };
    const [errors, setErrors] = useState(errorsObj)
    const [hideWalletAddress, setHideWalletAddress] = useState(false)
    const [currencyRate, setCurrencyRate] = useState(null)
    const [formData, setFormData] = useState({
        withdrawMethod: '',
        withdrawTo: '',
        walletAddress: '',
        amount: '',
        transactionPassword: '',
    });

    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getWithdrawalTypeList, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setWithdrwalTypeFromList(result.withdrawalTypeFromList);
                    setWithdrawalTypeToList(result.withdrawalTypeToList);
                    let newFormData = { ...formData };
                    newFormData.walletAddress = result.walletAddress;
                    setFormData(newFormData);
                    // setUsdtBalance(result.usdtBalance)
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    useEffect(() => {
        getBalance();
    }, [props.auth.auth.token, formData.withdrawMethod])

    useEffect(() => {
        if (formData.withdrawTo) {
            let filterData = withdrawalTypeToList.filter(item => item.key === formData.withdrawTo);
            if (filterData[0].extra.rate) {
                setHideWalletAddress(true);
                setCurrencyRate(filterData[0].extra.rate);
            } else {
                setHideWalletAddress(false);
                setCurrencyRate(null);
            }
        }
    }, [formData.withdrawTo])

    const getBalance = () => {
        if (formData.withdrawMethod) {
            fetch(api.getBalance + '?walletType=' + formData.withdrawMethod, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
            })
                .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {

                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        setBalanceAmount(result.balance)
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const submitRequest = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!formData.withdrawMethod) {
            errorMsg.withdrawMethod = 'This field is required.';
            check = false;
        } else {
            if (formData.withdrawMethod !== 'COMMISSION') {
                if (!formData.withdrawTo) {
                    errorMsg.withdrawTo = 'This field is required.';
                    check = false;
                }else{
                    let filterData = withdrawalTypeToList.filter(item => item.key === formData.withdrawTo);
                    if (filterData[0].extra.rate) {
                        
                    } else {
                        if (!formData.walletAddress) {
                            errorMsg.walletAddress = 'This field is required.';
                            check = false;
                        }
                    }
                }
                
            }
        }

        if (!formData.amount) {
            errorMsg.amount = 'This field is required.';
            check = false;
        } else {
            if (formData.amount <= 0) {
                errorMsg.amount = 'Amount must bigger than 0';
                check = false;
            }
        }
        if (!formData.transactionPassword) {
            errorMsg.transactionPassword = 'This field is required.';
            check = false;
        }

        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            let data = {
                "withdrawalTypeFrom": formData.withdrawMethod,
                "withdrawalTypeTo": formData.withdrawTo,
                "walletAddress": formData.walletAddress,
                "amount": formData.amount,
                "transactionPassword": formData.transactionPassword
            }
            fetch(api.withdraw, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        swal(translate(props.lang, 'Success'), result.message, "success");
                        history.push('/home/withdrawal');
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e.target;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    return (
        <>
            <div className="profile withdrawal  mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/home/withdrawal" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Withdrawal")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            <Row>
                                <Col lg={6} md={12} sm={12} xs={12} className="mb-3">
                                    <div>
                                        <div className={`input-group ${errors.withdrawMethod ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Withdraw Method")}</span>
                                            </div>
                                            <Input
                                                type="select"
                                                name="withdrawMethod"
                                                className="form-control input-transparent font-weight-400 font-13 text-white letter-spacing-05px text-end dark-dropdown-bg"
                                                value={formData.withdrawMethod}
                                                onChange={(e) => handleOptionSelected(e, "withdrawMethod")}>
                                                <option value="">{translate(props.lang, "Please Select")}</option>
                                                {withdrawalTypeFromList.map((item, i) =>
                                                    <option value={item.key} key={i}>{item.value}</option>
                                                )}
                                            </Input>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.withdrawMethod)}</span>
                                    </div>
                                </Col>
                                {formData.withdrawMethod !== 'COMMISSION' ? (
                                    <>
                                        <Col lg={6} md={12} sm={12} xs={12} className="mb-3">
                                            <div>
                                                <div className={`input-group ${errors.withdrawTo ? 'errorBorder' : ''}`}>
                                                    <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Withdraw To")}</span>
                                                    </div>
                                                    <Input
                                                        type="select"
                                                        name="withdrawTo"
                                                        className="form-control input-transparent font-weight-400 font-13 text-white letter-spacing-05px text-end dark-dropdown-bg"
                                                        value={formData.withdrawTo}
                                                        onChange={(e) => handleOptionSelected(e, "withdrawTo")}>
                                                        <option value="">{translate(props.lang, "Please Select")}</option>
                                                        {withdrawalTypeToList.map((item, i) =>
                                                            <option value={item.key} key={i}>{item.value}</option>
                                                        )}
                                                    </Input>
                                                </div>
                                                <span className="error errorTextRed">{translate(props.lang, errors.withdrawTo)}</span>
                                            </div>
                                        </Col>
                                        {hideWalletAddress ? null : (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                                <div>
                                                    <div className={`input-group ${errors.walletAddress ? 'errorBorder' : ''}`}>
                                                        <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                            <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Transfer Wallet Address")}</span>
                                                        </div>
                                                        <Input
                                                            type="text"
                                                            name="walletAddress"
                                                            placeholder={translate(props.lang, "Please Enter Wallet Address")}
                                                            className={`form-control input-transparent font-weight-400 font-13 text-end text-white letter-spacing-05px`}
                                                            value={formData.walletAddress}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                    <span className="error errorTextRed">{translate(props.lang, errors.walletAddress)}</span>
                                                </div>
                                            </Col>
                                        )}
                                    </>
                                ) : null}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                    <div>
                                        <div className={`input-group`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Balance")}</span>
                                            </div>
                                            <Input
                                                type="text"
                                                name="balanceAmount"
                                                className={`form-control input-transparent font-weight-400 font-14 text-end text-white letter-spacing-05px`}
                                                value={balanceAmount}
                                                readOnly
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                    <div>
                                        <div className={`input-group ${errors.amount ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Withdraw Amount")}</span>
                                            </div>
                                            <Input
                                                type="number"
                                                name="amount"
                                                placeholder={translate(props.lang, "Please Enter amount")}
                                                className={`form-control input-transparent font-weight-400 font-14 text-end text-white letter-spacing-05px`}
                                                value={formData.amount}
                                                autoComplete="off"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                            <div className='d-flex align-items-center justify-content-center transparent-bg pe-3'>
                                                <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{formData.withdrawMethod}</span>
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.amount)}</span>
                                    </div>
                                </Col>
                                {currencyRate ? (
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.amount ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Total Withdraw Amount")}</span>
                                                </div>
                                                <Input
                                                    type="number"
                                                    name="amount"
                                                    className={`form-control input-transparent font-weight-400 font-14 text-end text-white letter-spacing-05px`}
                                                    value={formData.amount * currencyRate}
                                                    readOnly
                                                />
                                                <div className='d-flex align-items-center justify-content-center transparent-bg pe-3'>
                                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{formData.withdrawTo}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ) : null}
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                    <div>
                                        <div className={`input-group ${errors.transactionPassword ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg ps-3'>
                                                <span className={`font-weight-400 font-13 text-white letter-spacing-05px`}>{translate(props.lang, "Transaction Password")}</span>
                                            </div>
                                            <Input
                                                type={showPassword ? 'text' : 'password'}
                                                name="transactionPassword"
                                                placeholder={translate(props.lang, "Please Enter Transaction Password")}
                                                className={`form-control input-transparent font-weight-400 font-14 text-end text-white letter-spacing-05px`}
                                                value={formData.transactionPassword}
                                                autoComplete="new-password"
                                                onChange={e => handleAddFormChange(e)}
                                            />
                                            <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? (<img src={require('../../../../images/icons/hide.png').default} className="img-fluid icon" alt="hide" />) : (<img src={require('../../../../images/icons/show.png').default} className="img-fluid icon" alt="show" />)}
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.transactionPassword)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <div className="mt-2">
                                <span className="font-weight-400 font-13 letter-spacing-003em secondary-text">{translate(props.lang, "Notes:")} -</span>
                            </div>
                            <button className={`mt-5 btn primary-btn btn-block font-weight-400 font-16`} disabled={loading} onClick={() => submitRequest()}>
                                {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Submit")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(WithdrawRequest));