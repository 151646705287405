const EN = {
    "Datetime": "Datetime",
    "Amount": "Amount",
    "Max.": "Max.",
    "Days": "Days",
    "deposit": "Deposit",
    "withdraw": "Withdraw",
    "Type": "Type",
    "Loading Data": "Loading Data",
    "Reference no": "Reference no",
    "Time": "Time",
    "Contact Us": "Contact Us",
    "Status": "Status",
    "pending": "Pending",
    "new": "New",
    "approved": "Approved",
    "rejected": "Rejected",
    "active": "Active",
    "Inactive": "Inactive",
    "Ranking": "Ranking",
    "Downline User": "Downline User",
    "Loading": 'Loading',
    "Logout": "Logout",
    "Home": "Home",
    "Overview": "Overview",
    "My Income": "My Income",
    "current password": "Current Password",
    "Send Code": "Send Code",
    "My Bill": "My Bill",
    "No": "No",
    "Yes": "Yes",
    "Are you sure to cancel staking?": "Are you sure to cancel staking?",
    "Once confirm, you cant revert this action anymore": "Once confirm, you cant revert this action anymore",
    "Amount cannot less than": "Amount cannot less than",
    "Amount cannot greater than": "Amount cannot greater than",
    "Total Withdrawal": "Total Withdrawal",
    "Total Recharge": "Total Recharge",
    "Amount must bigger than 0": 'Amount must bigger than 0',
    "Are you sure to delete account?": "Are you sure to delete account?",
    "Once deleted, you will not able to recover your data": "Once deleted, you will not able to recover your data.",
    "Cancel": "Cancel",
    "Confirm Delete": "Confirm Delete",
    "Password must at least 6 characters": "Password must at least 6 characters",
    "Confirm password need to same with password": "Confirm password need to same with password",
    "Confirm password need to match with new password": "Confirm password need to match with new password",
    "Notes:": "Notes:",
    "Please Select": "Please Select",
    "Withdraw Method": "Withdraw Method",
    "Withdraw To": "Withdraw To",
    "Transfer Wallet Address": "Transfer Wallet Address",
    "Please Enter Wallet Address": "Please Enter Wallet Address",
    "Please Enter Transaction Password": "Please Enter Transaction Password",
    "Withdraw Amount": 'Withdraw Amount',
    "Please Enter amount": "Please Enter Amount",
    "View More": "View More",
    "Withdrawal History": "Withdrawal History",
    "If cancel staking within 1 year will not valid to get 100% interest.": "If cancel staking within 1 year will not valid to get 100% interest.",
    "Balance": "Balance",
    "confirm": "confirm",
    "Please enter staking": "Please enter staking",
    "Staking Daily Payout": "Staking Daily Payout",
    "Staking USDT": "Staking USDT",
    "Staking Name": "Staking Name",
    "Daily Income Rate": "Daily Income Rate",
    "Cancel Fees": "Cancel Fees",
    "Current Stake Amount": 'Current Stake Amount',
    "Staking Amount": 'Staking Amount',
    "Earn": "Earn",
    "Basic Infomation": "Basic Infomation",
    "Details": "Details",
    "investment": "investment",
    "Unit Price": "Unit Price",
    "Platform Management Fee": "Platform Management Fee",
    "Total Dividend": "Total Dividend",
    "Circle": "Circle",
    "Rush To Buy Date": "Rush To Buy Date",
    "Commission + ROI": 'Commission + ROI',
    "OTP code has sent to": "OTP code has sent to",
    "Please fill in all required fields with valid information.": "Please fill in all required fields with valid information.",
    "Invalid Email Format": "Invalid email format",
    "Submit": "Submit",
    "thank you!": "thank you!",
    "Back": "Back",
    "Success": "Success",
    "Error": "Error",
    "Create Personal Account": "Create Personal Account",
    "Sign Up": "Sign Up",
    "Already have an account?": "Already have an account?",
    "login now": "Login now",
    "verfication Code": "Verfication Code",
    "phone number": "Phone Number",
    "Register Successful": "Register Successful",
    "Forgot Password": "Forgot Password",
    "SEND CODE": "SEND CODE",
    "Confirm Password": "Confirm Password",
    "This field is required.": 'This field is required.',
    "By clicking “Submit” button, you agree to our Privacy and Terms of Use.": "By clicking “”Submit” button, you agree to our Privacy and Terms of Use. ",
    "BACK TO LOGIN": "BACK TO LOGIN",
    "RESEND CODE": "RESEND CODE",
    "Welcome Back": "Welcome Back",
    "Sign up now": "Sign up now",
    "Password": "Password",
    "Sign In": "Sign In",
    "Don’t have an account yet?": "Don’t have an account yet?",
    "Forgot Password ?": "Forgot Password ?",
    "Withdrawal": 'Withdrawal',
    "All": "All",
    "Recharge": "Recharge",
    "Number of Friends": "Number of Friends",
    "Daily Income": "Daily Income",
    "Commission": "Commission",
    "Total Commission": "Total Commission",
    "Change Language": "Change Language",
    "Remove this account form your device. After deletion, your account and data cannot be recovered.": "Remove this account form your device. After deletion, your account and data cannot be recovered.",
    "Delete Account Confirmation": "Delete Account Confirmation",
    "confirm new password": "Confirm New Password",
    "password": "Password",
    "new password": "New Password",
    "Staking Income": "Staking Income",
    "Mining Income": "Mining Income",
    "Investment of USDT": "Investment of USDT",
    "Staking": "Staking",
    "Investment Amount": "Investment Amount",
    "Income": "Income",
    "Final Income": "Final Income",
    "Termination Date": "Termination Date",
    "Next Settlement": "Next Settlement",
    "Purchase Date": "Purchase Date",
    "Mining": 'Mining',
    "Invite 10 friends to rent mining machine": "Invite 10 friends to rent mining machine",
    "Invite 5 friends to rent mining machine": "Invite 5 friends to rent mining machine",
    "Unlock Next Level": "Unlock Next Level",
    "My Mission": "My Mission",
    "Share Link": "Share Link",
    "Invitation Code": "Invitation Code",
    "Share with Friends": "Share with Friends",
    "Dividend": "Dividend",
    "Package": "Package",
    "Total Sales": "Total Sales",
    "Community Reward": "Community Reward",
    "Copied": "Copied",
    "referral code": 'Referral Code',
    "Upload IC or Passport": "Upload IC or Passport",
    "Select Country": "Select Country",
    "Account Name": "Account Name",
    "Account Number": "Account Number",
    "IC Number": "IC Number",
    "Bank Name": "Bank Name",
    "Update": "Update",
    "username": 'Username',
    "email": "Email",
    "Balance of USDT": "Balance of USDT",
    "User Management": "User Management",
    "My Referral Code": "My Referral Code",
    "Customer Support": "Customer Support",
    "Profile": 'Profile',
    "Settings": "Settings",
    "Bank Information": 'Bank Information',
    "Opps !": "Opps !",
    "Something went terribly wrong, please try again": "Something went terribly wrong, please try again",
    "Retry": "Retry",
    "Connection Failed": "Connection Failed",
    "Your internet connection is lost": "Your internet connection is lost",
    "No Results Found": "No Results Found",
    "There’s nothing for now": 'There’s nothing for now',
    "Login Password": 'Login Password',
    "Transaction Password": "Transaction Password",
    "Delete Account": "Delete Account",

    "email verfication Code": "Email Verfication Code",
    "Dial Code": "Dial Code",
    "Phone No.": 'Phone No.',
    "phone verfication Code": 'Phone Verfication Code',

}

export default EN;