import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { api } from '../../utils/api';
import { translate } from '../../utils/translate';
import swal from 'sweetalert';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
                ""
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/home');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history, path) {
    if(path){
        if(path.includes('/login') || path.includes('/register') || path.includes('/forgot-password') || path.includes('/thank-you')){
        }else{
            history.push('/login');
        }
    }else{
        history.push('/login');
    }
    localStorage.removeItem('starcloud');
    return {
        type: LOGOUT_ACTION,
    };
}

// export function loginAction(data, history, rememberMe) {
//     return (dispatch) => {
//         login(data.email, data.password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data, rememberMe);
//                 if(!rememberMe){
//                     runLogoutTimer(
//                         dispatch,
//                         response.data.expiresIn * 1000,
//                         history,
//                     );
//                 }
//                 dispatch(loginConfirmedAction(response.data));
// 				history.push('/home');                
//             })
//             .catch((error) => {
// 				//console.log(error);
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }

export function loginAction(data, history, lang, rememberMe) {
    return (dispatch) => {
        fetch(api.login, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json", 
                "Content-Language": lang.toLowerCase()
            }),
            body: JSON.stringify(data)
        })
        .then(response => Promise.all([response.status, response.text()]))
        // .then(response => Promise.all([response.ok, response.text()]))
        .then(responseJson => {
            let result = JSON.parse(responseJson[1]);
            if(responseJson[0] === 200){
                let userInfo = result;
                saveTokenInLocalStorage(userInfo, rememberMe);
                runLogoutTimer(
                    dispatch,
                    3600 * 1000,
                    history,
                );
                dispatch(loginConfirmedAction(userInfo));
                history.push('/home');  
            }else{
                swal(translate(lang, 'Error'), result.message, "error");
                const errorMessage = result.message;
                dispatch(loginFailedAction(errorMessage));
                dispatch(loadingToggleAction(false))
            }
        }).catch(error => {
            console.error("error",error);
        });
    };
}


export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
