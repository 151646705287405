import { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate"
import { Row, Col, Table } from 'reactstrap'
import { translate } from "../../../utils/translate";
import { api } from "../../../utils/api";
import "../../../css/profile.scss";
import NoResultFound from '../Common/NoResultFound';
import Loadingv3 from "../../components/Loading/Loadingv4";
import { replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";

const UserManagement = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const productPerpage = 10;
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [directDownlineMemberId, setDirectDownlineMemberId] = useState('');
    const [downlineList, setDownlineList] = useState([]);

    useEffect(() => {
        getUserManagement();
    }, [props.auth.auth.token, pageNumber, directDownlineMemberId])

    const getUserManagement = () => {
        setLoading(true);
        let url = api.userManagement + `?pageNo=${pageNumber}&pageSize=${productPerpage}`;
        if (directDownlineMemberId) {
            url = api.userManagement + `?pageNo=${pageNumber}&pageSize=${productPerpage}&directDownlineMemberId=${directDownlineMemberId}`;
        }
        fetch(url, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setResult(result)
                    setDownlineList(result.downlineList.memberDtoList);
                    setTotalRecord(result.downlineList.totalRecords);
                    let totalPage = Math.ceil(result.downlineList.totalRecords / productPerpage);
                    setPageCount(totalPage);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    return (
        <>
            <div className="profile usermanagement mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            {!directDownlineMemberId ? (
                                <Link to="/profile/overview" className="back-icon d-inline-block px-3 py-2">
                                    <FaChevronLeft />
                                </Link>
                            ) : (
                                <div className="back-icon d-inline-block px-3 py-2" onClick={() => setDirectDownlineMemberId(null)}>
                                    <FaChevronLeft />
                                </div>
                            )}

                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "User Management")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-black-2 px-4 py-3 cursor-pointer">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Row className="align-items-center">
                                    <Col lg={2} md={2} sm={6} xs={6}>
                                        <div className="">
                                            {result ? (
                                                <img src={replaceImgUrl(result.rankUrl)} className="img-fluid current-rank-img" alt="empty" />
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={6} xs={6}>
                                        <div className="h-100 d-flex flex-column justify-content-center w-100">
                                            <div>
                                                <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>{result ? result.fullName : ''}</span>
                                            </div>
                                            <div>
                                                <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text text-lowercase`}>{result ? result.email : ''}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="h-100 mt-lg-0 mt-md-0 mt-4">
                                        <Row className="display-flex-1">
                                            {/* <Col lg={4} md={4} sm={4} xs={4} className="display-flex-col1">
                                                <div className="card dark-dropdown-bg py-4 d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center">
                                                        <span className={`font-weight-400 font-13 letter-spacing-05px secondary-text`}>{translate(props.lang, "Package")}</span>
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <span className={`font-weight-400 font-18 letter-spacing-05px primary-text`}>{result ? result.package : ''}</span>
                                                    </div>
                                                </div>
                                            </Col> */}
                                            <Col lg={6} md={6} sm={6} xs={6} className="display-flex-col1">
                                                <div className="card dark-dropdown-bg py-4 d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center">
                                                        <span className={`font-weight-400 font-13 letter-spacing-05px secondary-text`}>{translate(props.lang, "Total Sales")}</span>
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <span className={`font-weight-400 font-18 letter-spacing-05px primary-text`}>{result ? result.totalSales.toLocaleString("en-US") : 0}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={6} className="display-flex-col1">
                                                <div className="card dark-dropdown-bg py-4 d-flex flex-column justify-content-center h-100">
                                                    <div className="text-center">
                                                        <span className={`font-weight-400 font-13 letter-spacing-05px secondary-text`}>{translate(props.lang, "Community Reward")}</span>
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <span className={`font-weight-400 font-18 letter-spacing-05px primary-text`}>{result ? result.communityReward.toLocaleString("en-US") : 0}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Table responsive className="w-100">
                                    <thead>
                                        <tr className="text-center">
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Ranking")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Downline User")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Package")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Total Sales")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Community Reward")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Status")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {downlineList.map((item, i) => (
                                            <tr key={i} className="text-center" onClick={() => setDirectDownlineMemberId(item.directDownlineMemberId)}>
                                                <td className={`font-weight-400 font-12 w-25`}>
                                                    <div className="d-flex align-items-center">
                                                        <img src={replaceImgUrl(item.levelUrl)} className="img-fluid w-15" alt={item.level} />
                                                        <span className={`font-weight-400 font-12 text-uppercase text-white ms-3`}>{item.level}</span>
                                                    </div>
                                                </td>
                                                <td className={`font-weight-400 font-12 text-capitalize text-white`}>{item.fullname}</td>
                                                <td className={`font-weight-400 font-12 primary-text`}>{item.package}</td>
                                                <td className={`font-weight-400 font-12 primary-text`}>{item.totalSales}</td>
                                                <td className={`font-weight-400 font-12 primary-text`}>{item.communityReward}</td>
                                                <td className={`font-weight-400 font-12 text-capitalize`}>
                                                    <div className={` status-user ${item.status.toLowerCase()} px-2 py-3`}>
                                                        {translate(props.lang, item.status.toLowerCase())}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {totalRecord === 0 && !loading ? (
                                            <tr>
                                                <td colspan={6}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                        {totalRecord > 0 ? (
                            <div>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                                    previousLinkClassName={"pagination__previouslink text-blue-1"}
                                    nextLinkClassName={"pagination__nextlink text-blue-1"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(UserManagement));