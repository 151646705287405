import { FaChevronLeft, FaCopy } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from 'reactstrap'
import { Link, withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import QRCode from "react-qr-code";
import { translate } from "../../../utils/translate";
import Loadingv3 from "../../components/Loading/Loadingv4";
import { api } from "../../../utils/api";
import "../../../css/profile.scss";
import { copyText, replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";

const ReferralCode = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.token])

    const getProfile = () => {
        setLoading(true);
        fetch(api.getProfile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    dispatch({
                        type: 'UPDATE_PROFILE',
                        profile: result
                    });
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const downloadImage = () => {
        const svg = document.getElementById("qrcode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "qrcode";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }

    return (
        <>
            <div className="profile referralCode mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/profile/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "My Referral Code")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            {loading ? (<Loadingv3 />) : (
                                <Row className="align-items-start">
                                    <Col lg={8} md={8} sm={12} xs={12} className="mt-3 mt-lg-0 mt-md-0">
                                        <Row className="align-items-center mb-3">
                                            <Col lg={2} md={2} sm={4} xs={4}>
                                                <div className="text-center">
                                                    {props.auth.profile.profileImage ? (
                                                        <img src={replaceImgUrl(props.auth.profile.profileImage)} className="img-fluid" alt="profile-img" />
                                                    ) : (
                                                        <img src={require('../../../images/profile/empty-img.png').default} className="img-fluid" alt="empty" />
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={10} md={10} sm={8} xs={8}>
                                                <div className="text-start">
                                                    <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>{props.auth.profile.fullName}</span>
                                                </div>
                                                <div className="text-start">
                                                    <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text text-lowercase`}>{props.auth.auth.claims.sub}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className={`input-group py-3 px-4 w-100`}>
                                            <div className="w-75">
                                                <span className="font-weight-400 font-14 secondary-text letter-spacing-05px">{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                            </div>
                                            <div className='w-25 d-flex align-items-center justify-content-end transparent-bg text-end' onClick={() => copyText(window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode, props.lang)}>
                                                <FaCopy />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <div className="referral-qr-container p-3 w-75" onClick={() => downloadImage()}>
                                            <QRCode
                                                id="qrcode"
                                                size={128}
                                                fgColor="#000000"
                                                bgColor="transparent"
                                                className="h-100 w-100"
                                                value={window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}
                                                viewBox={`0 0 128 128`}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(ReferralCode));