import { lazy, Suspense, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './vendor/datatables/css/dataTables.min.css';
import "./css/style.css";
import "./css/custom.scss";
import Loadingv1 from './jsx/components/Loading/Loadingv1';

const Register = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/auth/Register')), 500);
    });
});
const ForgotPassword = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/auth/ForgotPassword')), 500);
    });
});
const Login = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./jsx/pages/auth/Login')), 1000);
    });
});
const ThankYou = lazy(() => import('./jsx/pages/auth/ThankYou'));

function App(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
        if (localStorage.getItem('starcloud-lang')) {
            dispatch({
                type: "UPDATE_LANGUAGE",
                lang: localStorage.getItem('starcloud-lang'),
            });
        }
        checkAutoLogin(dispatch, props.history, location.pathname);
    }, [dispatch, props.history, location.pathname]);

    useEffect(() => {
        history.listen((location) => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });
      }, [history]);

    let routes = (
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register' component={Register} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/thank-you' component={ThankYou} />
        </Switch>
    );
    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );

    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <Loadingv1 />
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));

