const SidebarMenu = [
    { 
        menu: "Home", 
        activeImg: require('../images/menu/home_active.png').default,
        inactiveImg: require('../images/menu/home.png').default,
        pathName: '/home',
        allPath: ['/home','/home/overview','/home/recharge', '/home/withdrawal', '/home/my-bill', '/home/commission'],
        children: [
            {
                menu: "Overview",
                pathName: '/home',
            },
            {
                menu: "Recharge",
                pathName: '/home/recharge',
            },
            {
                menu: "Withdrawal",
                pathName: '/home/withdrawal',
            },
            {
                menu: "My Bill",
                pathName: '/home/my-bill',
            },
            {
                menu: "Commission",
                pathName: '/home/commission',
            },
        ] 
    },
    { 
        menu: "My Income", 
        activeImg: require('../images/menu/my_income_active.png').default,
        inactiveImg: require('../images/menu/my_income.png').default,
        pathName: "/my-income",
        allPath: ["/my-income"],
        children: [] 
    },
    { 
        menu: "Dividend", 
        activeImg: require('../images/menu/dividend_active.png').default,
        inactiveImg: require('../images/menu/dividend.png').default,
        pathName: "/dividend",
        allPath: ["/dividend"],
        children: [] 
    },
    { 
        menu: "Profile", 
        activeImg: require('../images/menu/profile_active.png').default,
        inactiveImg: require('../images/menu/profile.png').default,
        pathName: '/profile',
        allPath: ['/profile/overview', '/profile/', '/profile/user-management', '/profile/bank-information', '/profile/referral-code', '/profile/settings', '/profile/customer-support', '/profile/settings/login-password', '/profile/settings/transaction-password', 'profile/settings/delete-account'],
        children: [
            {
                menu: "Overview",
                pathName: '/profile/overview',
            },
            {
                menu: "Profile",
                pathName: '/profile',
            },
            {
                menu: "User Management",
                pathName: '/profile/user-management',
            },
            {
                menu: "Bank Information",
                pathName: '/profile/bank-information',
            },
            {
                menu: "My Referral Code",
                pathName: '/profile/referral-code',
            },
            {
                menu: "Settings",
                pathName: '/profile/settings',
            },
            // {
            //     menu: "Customer Support",
            //     pathName: '/profile/customer-support',
            // },
            // {
            //     menu: "Logout",
            //     pathName: '/profile/logout',
            // },
        ]
    },
]

export default SidebarMenu;