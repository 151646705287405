import { FaRegCopy } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/dividend.scss";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { api } from "../../../utils/api";
import { copyText, calculatePercent, replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";
import Loadingv3 from "../../components/Loading/Loadingv4";

const Dividend = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [dividendInfo, setDividendInfo] = useState({
        invitedList: [],
        myMission: null,
        shareWithFriendList: []
    })
    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true);
        fetch(api.getUserDividend, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setDividendInfo(result)
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }


    return (
        <>
            <div className="dividend mt-4 mt-lg-0 mt-md-2">
                {/* <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={12} md={12} sm={11} xs={11} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Dividend")}</span>
                            </div>
                        </Col>
                    </Row>
                </div> */}

                <div className="mt-3 mb-4">
                    <Row className="justify-content-center align-items-center">
                        <Col lg={12} md={12} sm={11} xs={11} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "My Mission")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card missionLevelBg px-2 px-lg-4 px-md-4 py-3">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Row className="justify-content-center align-items-center py-3">
                                    <Col lg={1} md={1} sm={10} xs={10} className="mb-3 mb-lg-0 mb-md-0">
                                        {
                                            dividendInfo.myMission ? dividendInfo.myMission.currentRankLogo ?
                                                (
                                                    <img src={replaceImgUrl(dividendInfo.myMission.currentRankLogo)} className="img-fluid rank-logo" alt={'referral'} />
                                                ) : null : null
                                        }
                                    </Col>
                                    <Col lg={11} md={11} sm={11} xs={11}>
                                        <div>
                                            <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, 'Unlock Next Level')}</span>
                                        </div>
                                        <div className="my-2">
                                            <span className={`font-weight-400 font-12 letter-spacing-05px secondary-text text-uppercase`}>{dividendInfo.myMission ? dividendInfo.myMission.nextRankName : ''}</span>
                                        </div>
                                        <div className="d-flex w-100 align-items-center">
                                            <div className="w-75">
                                                <div className="progress2 progress-moved position-relative">
                                                    <div className={`progress-bar2 progress-with-text  ${calculatePercent(dividendInfo.myMission ? dividendInfo.myMission.currentTeamSales : 0, dividendInfo.myMission ? dividendInfo.myMission.targetDirectDownline : 0)}`} >
                                                    </div>
                                                    <span className={`font-weight-300 font-8 letter-spacing-003em text-white percentage`}>{dividendInfo.myMission ? dividendInfo.myMission.currentTeamSales + "/" + dividendInfo.myMission.targetDirectDownline : ''}</span>
                                                </div>
                                            </div>
                                            <div className="text-end w-25">
                                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>{dividendInfo.myMission ? dividendInfo.myMission.targetDirectDownlineString : ''}</span>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>

                <Row className="mt-1">
                    {loading ? (
                        <div className="card qr-card-bg px-4 py-4">
                            <Loadingv3 />
                        </div>
                    ) : (
                        dividendInfo.invitedList.map((item, i) => (
                            <Col lg={4} md={4} sm={12} xs={12} key={i}>
                                <div className="card qr-card-bg px-4 py-5">
                                    <div className="row justify-content-center align-items-start">
                                        <div className="col-6">
                                            <img src={replaceImgUrl(item.imageUrl)} className="img-fluid w-50" alt={'level'} />
                                        </div>
                                        <div className="col-6 text-end">
                                            <span className={`font-weight-300 font-14 letter-spacing-05px text-white`}>{translate(props.lang, "Dividend")}</span>
                                        </div>
                                    </div>
                                    <div className="mt-4 mb-2">
                                        <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{item.mission}</span>
                                    </div>
                                    <div className="progress2 progress-moved position-relative">
                                        <div className={`progress-bar2 progress-with-text  ${calculatePercent(item.totalDirectDownline, item.targetDownline)}`} >
                                        </div>
                                        <span className={`font-weight-300 font-8 letter-spacing-003em text-white percentage`}>{item.totalDirectDownline + "/" + item.targetDownline}</span>
                                    </div>
                                    <div className="mt-2 text-center">
                                        <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>{item.invitedReward}</span>
                                    </div>
                                </div>
                            </Col>
                        ))
                    )}
                    {/* <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card qr-card-bg px-4 py-4">
                            <div>
                                <img src={require('../../../images/dividend/lv1.png').default} className="img-fluid w-25" alt={'level'} />
                            </div>
                            <div className="mt-3 mb-2">
                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, 'Invite 5 friends to rent mining machine')}</span>
                            </div>
                            <div className="progress2 progress-moved">
                                <div className="progress-bar2 progress-without-text" >
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>+5 USDT</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card qr-card-bg px-4 py-4">
                            <div>
                                <img src={require('../../../images/dividend/lv1.png').default} className="img-fluid w-25" alt={'level'} />
                            </div>
                            <div className="mt-3 mb-2">
                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, 'Invite 10 friends to rent mining machine')}</span>
                            </div>
                            <div className="progress2 progress-moved">
                                <div className="progress-bar2 progress-without-text" >
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>+10 USDT</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card qr-card-bg px-4 py-4">
                            <div>
                                <img src={require('../../../images/dividend/lv1.png').default} className="img-fluid w-25" alt={'level'} />
                            </div>
                            <div className="mt-3 mb-2">
                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, 'Invite 15 friends to rent mining machine')}</span>
                            </div>
                            <div className="progress2 progress-moved">
                                <div className="progress-bar2 progress-without-text" >
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>+15 USDT</span>
                            </div>
                        </div>
                    </Col> */}
                </Row>

                <div className="mt-3 mb-2">
                    <Row className="justify-content-center align-items-center">
                        <Col lg={12} md={12} sm={11} xs={11} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Share with Friends")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <div className="card qr-card-bg px-2 px-lg-4 px-md-4 py-3">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Row className="justify-content-center align-items-center h-100">
                                    <Col lg={2} md={2} sm={10} xs={10} className="mb-lg-0 mb-md-0 mb-3">
                                        <div className="icon-container px-lg-3 px-2 py-lg-3 py-2 d-inline-block text-center">
                                            <img src={require('../../../images/dividend/referral.png').default} className="img-fluid icon-dividend" alt={'referral'} />
                                        </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10} className="h-100">
                                        <div className="d-flex flex-column justify-content-center h-100 ">
                                            <div>
                                                <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, 'Invitation Code')}</span>
                                            </div>
                                            <div className="mt-2 d-flex w-100 align-items-center">
                                                <div className="w-75">
                                                    <span className={`font-weight-400 font-12 letter-spacing-05px secondary-text`}>{props.auth.auth.claims.referralCode}</span>
                                                </div>
                                                <div className="w-25 text-end">
                                                    <FaRegCopy className="font-20" onClick={() => copyText(props.auth.auth.claims.referralCode, props.lang)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                        <div className="card qr-card-bg px-2 px-lg-4 px-md-4 py-3">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Row className="justify-content-center align-items-center h-100">
                                    <Col lg={2} md={2} sm={10} xs={10} className="mb-lg-0 mb-md-0 mb-3">
                                        <div className="icon-container px-lg-3 px-2 py-lg-3 py-2 d-inline-block text-center">
                                            <img src={require('../../../images/dividend/share.png').default} className="img-fluid icon-dividend" alt={'referral'} />
                                        </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <div className="d-flex flex-column justify-content-center h-100 ">
                                            <div>
                                                <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, 'Share Link')}</span>
                                            </div>
                                            <div className="mt-2 d-flex w-100 align-items-center">
                                                <div className="w-75">
                                                    <span className={`font-weight-400 font-12 letter-spacing-05px secondary-text`}>{window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode}</span>
                                                </div>
                                                <div className="w-25 text-end">
                                                    <FaRegCopy className="font-20" onClick={() => copyText(window.location.origin + '/register?referral=' + props.auth.auth.claims.referralCode, props.lang)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Dividend));