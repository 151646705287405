import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Row, Col } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { Link } from "react-router-dom";

const Settings = (props) => {
    return (
        <>
            <div className="profile setting mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/profile/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Settings")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Link className="card card-black-2 px-4 py-5 cursor-pointer" to="/profile/settings/login-password">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <div>
                                        <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, "Login Password")}</span>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                    <FaChevronRight />
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Link className="card card-black-2 px-4 py-5 cursor-pointer" to="/profile/settings/transaction-password">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <div>
                                        <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, "Transaction Password")}</span>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                    <FaChevronRight />
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Link className="card card-black-2 px-4 py-5 cursor-pointer" to="/profile/settings/delete-account">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <div>
                                        <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, "Delete Account")}</span>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                    <FaChevronRight />
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <Link className="card card-black-2 px-4 py-5 cursor-pointer" to="/profile/settings/change-language">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={10} md={10} sm={10} xs={10}>
                                    <div>
                                        <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, "Change Language")}</span>
                                    </div>
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                    <FaChevronRight />
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Settings);