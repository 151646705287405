/// Pages
import HomeOverview from "../jsx/pages/Home/Overview";
import Recharge from "../jsx/pages/Home/Recharge";
import Commission from "../jsx/pages/Home/Commission";
import Withdrawal from "../jsx/pages/Home/Withdrawal";
import MyBill from "../jsx/pages/Home/MyBill";
import Dividend from "../jsx/pages/Dividend/Dividend";
import Income from "../jsx/pages/Income/Income";
import Profile from "../jsx/pages/Profile/Profile";
import ProfileOverview from "../jsx/pages/Profile/Overview";
import BankInformation from "../jsx/pages/Profile/BankInformation";
import UserManagement from "../jsx/pages/Profile/UserManagement";
import ReferralCode from "../jsx/pages/Profile/ReferralCode";
import Settings from "../jsx/pages/Profile/Settings";
import CustomerSupport from "../jsx/pages/Profile/CustomerSupport";
import LoginPassword from "../jsx/pages/Profile/LoginPassword";
import TransactionPassword from "../jsx/pages/Profile/TransactionPassword";
import DeleteAccount from "../jsx/pages/Profile/DeleteAccount";
import Language from "../jsx/pages/Profile/Language";
import PackageInfo from "../jsx/pages/Home/PackageInfo";
import StakingDetails from "../jsx/pages/Home/StakingDetails";
import WithdrawRequest from "../jsx/pages/Home/Withdrawal/WithdrawRequest";
import WithdrawHistory from "../jsx/pages/Home/Withdrawal/WithdrawHistory";

const routes =  [
    { url: "", component: HomeOverview, name: "Overview" },
    { url: "home", component: HomeOverview, name: "Overview" },
    { url: "home/overview", component: HomeOverview, name: "Overview" },
    { url: "home/recharge", component: Recharge, name: "Recharge" },
    { url: "home/withdrawal", component: Withdrawal, name: "Withdrawal" },
    { url: "home/withdrawal/request", component: WithdrawRequest, name: "Withdraw Request" },
    { url: "home/withdrawal/history", component: WithdrawHistory, name: "Withdrawal History" },
    { url: "home/my-bill", component: MyBill, name: "My Bill" },
    { url: "home/commission", component: Commission, name: "Commission" },
    { url: "home/package/:id", component: PackageInfo, name: "Package" },
    { url: "home/staking", component: StakingDetails, name: "Staking Details" },

    { url: "my-income", component: Income, name: "My Income" },
    { url: "dividend", component: Dividend, name: "Dividend" },

    { url: "profile", component: Profile, name: "Profile" },
    { url: "profile/overview", component: ProfileOverview, name: "Profile Overview" },
    { url: "profile/user-management", component: UserManagement, name: "User Management" },
    { url: "profile/bank-information", component: BankInformation, name: "Bank Information" },
    { url: "profile/referral-code", component: ReferralCode, name: "Referral Code" },
    { url: "profile/settings", component: Settings, name: "Settings" },
    { url: "profile/customer-support", component: CustomerSupport, name: "Customer Support" },
    { url: 'profile/settings/login-password', component: LoginPassword, name: 'Login Password'},
    { url: 'profile/settings/transaction-password', component: TransactionPassword, name: 'Transaction Password'},
    { url: 'profile/settings/delete-account', component: DeleteAccount, name: 'Delete Account'},
    { url: 'profile/settings/change-language', component: Language, name: 'Change Language'}

];
export default routes;