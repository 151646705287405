import { FaChevronLeft, FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Input } from 'reactstrap'
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { Link, withRouter } from "react-router-dom";
import { useState } from "react";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";

const TransactionPassword = (props) => {
    const dispatch = useDispatch();
    const errorsObj = { password: '', newPassword: '', confirmPassword: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const changePassword = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!password) {
            errorMsg.password = "This field is required.";
            check = false;
        }
        if (!newPassword) {
            errorMsg.newPassword = "This field is required.";
            check = false;
        } else {
            if (newPassword.length < 6) {
                errorMsg.newPassword = "Password must at least 6 characters";
                check = false;
            }
        }
        if (!confirmPassword) {
            errorMsg.confirmPassword = "This field is required.";
            check = false;
        }
        if (newPassword && confirmPassword) {
            if (confirmPassword !== newPassword) {
                errorMsg.confirmPassword = "Confirm password need to match with new password";
                check = false;
            }
        }

        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            let data = {
                "oldTransactionPassword": password,
                "newTransactionPassword": newPassword,
                "confirmTransactionPassword": confirmPassword
            }
            fetch(api.changeTransactionPassword, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
            .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        swal(translate(props.lang, 'Success'), result.message, "success");
                        resetForm();
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const resetForm = () => {
        setPassword('');
        setNewPassword('');
        setConfirmPassword('');
    }

    return (
        <>
            <div className="profile mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link className="back-icon d-inline-block px-3 py-2" to="/profile/settings">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Transaction Password")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-5 py-5">
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                    <div>
                                        <div className={`input-group ${errors.password ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                <img src={require('../../../images/icons/lock.png').default} className="img-fluid icon" alt="lock" />
                                            </div>
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                placeholder={translate(props.lang, "password")}
                                                className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                value={password}
                                                autoComplete="off"
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                            <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? (<img src={require('../../../images/icons/hide.png').default} className="img-fluid icon" alt="hide" />) : (<img src={require('../../../images/icons/show.png').default} className="img-fluid icon" alt="show" />)}
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.password)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                                    <div>
                                        <div className={`input-group ${errors.newPassword ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                <img src={require('../../../images/icons/newpassword.png').default} className="img-fluid icon" alt="lock" />
                                            </div>
                                            <Input
                                                type={showNewPassword ? "text" : "password"}
                                                name="newPassword"
                                                placeholder={translate(props.lang, "new password")}
                                                className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                value={newPassword}
                                                autoComplete="off"
                                                onChange={e => setNewPassword(e.target.value)}
                                            />
                                            <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowNewPassword(!showNewPassword)}>
                                                {showNewPassword ? (<img src={require('../../../images/icons/hide.png').default} className="img-fluid icon" alt="hide" />) : (<img src={require('../../../images/icons/show.png').default} className="img-fluid icon" alt="show" />)}
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.newPassword)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                                    <div>
                                        <div className={`input-group ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                <img src={require('../../../images/icons/confirmpassword.png').default} className="img-fluid icon" alt="lock" />
                                            </div>
                                            <Input
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirmPassword"
                                                placeholder={translate(props.lang, "confirm new password")}
                                                className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                value={confirmPassword}
                                                autoComplete="off"
                                                onChange={e => setConfirmPassword(e.target.value)}
                                            />
                                            <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                {showConfirmPassword ? (<img src={require('../../../images/icons/hide.png').default} className="img-fluid icon" alt="hide" />) : (<img src={require('../../../images/icons/show.png').default} className="img-fluid icon" alt="show" />)}
                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.confirmPassword)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <button className={`btn primary-btn btn-block font-weight-400 font-16`} disabled={loading} onClick={() => changePassword()}>
                                {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(TransactionPassword));
