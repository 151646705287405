import { useState, useEffect } from 'react';
import { FaChevronLeft, FaSpinner } from 'react-icons/fa';
import { connect, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import swal from 'sweetalert';
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Input } from 'reactstrap';
import { translate } from "../../../utils/translate";
import { api } from '../../../utils/api';
import { logout } from '../../../store/actions/AuthActions';
import Loadingv3 from '../../components/Loading/Loadingv4';

const StakingDetails = (props) => {
    const dispatch = useDispatch();
    let errorsObj = { password: '', amount: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState('')
    const [password, setPassword] = useState('');
    const [passwordModal, setPasswordModal] = useState(false);

    const [stakingData, setStakingData] = useState({
        stakingDailyPayout: null,
        stakingDetails: null,
    });
    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getStakingDetails, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setStakingData(result);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const openModal = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!amount) {
            errorMsg.amount = 'This field is required.';
            check = false;
        } else {
            if (amount < stakingData.stakingDetails.minAmount) {
                errorMsg.amount = translate(props.lang, 'Amount cannot less than') + " " + stakingData.stakingDetails.minAmount;
                check = false;
            }
            if (amount > stakingData.stakingDetails.maxAmount) {
                errorMsg.amount = translate(props.lang, 'Amount cannot greater than') + " " + stakingData.stakingDetails.maxAmount;
                check = false;
            }
        }
        setErrors(errorMsg)
        if (check) {
            setPasswordModal(true);
            setPassword('');
        }
    }

    const closeModal = () => {
        setPasswordModal(false);
        setPassword('');
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!password) {
            errorMsg.password = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            let data = {
                "stakingAmount": amount,
                "transactionPassword": password
            }
            fetch(api.doStake, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        swal(result.message)
                            .then((value) => {
                                closeModal();
                            });
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const onChangeAmount = (event) => {
        var regex = /^\d*[.]?\d*$/;
        event.preventDefault();
        if(regex.test(event.target.value)){
            setAmount(event.target.value)
        }else{
            return;
        }
    }

    return (
        <>
            <div className="home package-info mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link className="back-icon d-inline-block px-3 py-2" to="/home/overview" >
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Staking USDT")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                {loading ? (
                    <div className="card card-black px-4 py-4 mt-4">
                        <Loadingv3 />
                    </div>
                ) : (
                    <Row className='mt-4 py-4 card-black '>
                        <Col lg={3} md={4} sm={12} xs={12}>
                            <div className='px-4 py-5'>
                                <div className='mb-3'>
                                    <span className='font-weight-400 font-13 text-white d-block'>{translate(props.lang, "Staking Name")}</span>
                                </div>
                                <div>
                                    <span className='font-weight-400 font-16 text-white d-block'>{stakingData.stakingDetails ? stakingData.stakingDetails.stakingName : ''}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={9} md={8} sm={12} xs={12}>
                            <div className='card-transparent-borderLeft px-5 py-5'>
                                <div className='mt-4'>
                                    <Row className='mb-2'>
                                        <Col>
                                            <div>
                                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, "Staking")}</span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-end'>
                                                <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text`}>{translate(props.lang, "Balance")}: {stakingData.stakingDetails ? stakingData.stakingDetails.maxAmount : ''}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-2 align-items-center'>
                                        <Col>
                                            <div>
                                                <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text`}>{translate(props.lang, "Please enter staking")}</span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='text-end'>
                                                <Input
                                                    type="text"
                                                    name="amount"
                                                    placeholder={translate(props.lang, "0")}
                                                    className={`form-control input-transparent text-end font-weight-400 font-14 secondary-text letter-spacing-05px ${errors.amount ? 'errorBorder' : ''} `}
                                                    value={amount}
                                                    autoComplete="off"
                                                    onChange={(e) => onChangeAmount(e)}
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='text-end'>
                                        <span className={`errorTextRed`}>{translate(props.lang, errors.amount)}</span>
                                    </div>
                                </div>
                                <div className={`mt-4 btn primary-btn btn-block font-weight-400 font-16 text-capitalize`} onClick={() => openModal()}>
                                    {translate(props.lang, "confirm")}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                <div className='pt-3 text-center'>
                    <span className={`font-weight-400 font-12 letter-spacing-05px secondary-text`}>{stakingData.stakingDetails ? stakingData.stakingDetails.remark : ''}</span>
                </div>
                <Modal className="fade passwordModal" show={passwordModal} centered>
                    <Modal.Body className="py-5 px-4">
                        <div>
                            <span className={`font-weight-400 font-24 letter-spacing-003em text-white text-capitalize`}>{translate(props.lang, "Transaction Password")}</span>
                        </div>
                        <div className="mt-4">
                            <div className={`input-group ${errors.password ? 'errorBorder' : ''}`}>
                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                    <img src={require('../../../images/icons/lock.png').default} className="img-fluid w-50" />
                                </div>
                                <input
                                    type={'password'}
                                    className="form-control input-transparent"
                                    value={password}
                                    autoComplete="off"
                                    placeholder={translate(props.lang, "Password")}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>
                            <span className="error errorTextRed">{translate(props.lang, errors.password)}</span>
                        </div>
                        <Row className="justify-content-center align-items-center mt-4">
                            <Col>
                                <button className={`btn cancel-btn btn-block font-weight-400 font-16`} onClick={() => closeModal()} disabled={loading}>
                                    {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Cancel")}
                                </button>
                            </Col>
                            <Col>
                                <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={() => submitForm()} disabled={loading}>
                                    {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Submit")}
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(StakingDetails));