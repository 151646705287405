import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../../css/header.scss";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/home/overview" className="brand-logo">
		<Fragment>
			{toggle ? (
				<img src={require('../../../images/logo/logo-1.png').default} className="img-fluid header-logo" />
			) : (
				<img src={require('../../../images/logo/logo-2.png').default} className="img-fluid header-logo-text" />
			)}
		</Fragment>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
