import { FaChevronLeft, FaPhone, FaPhoneAlt, FaPhoneSquare } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Row, Col } from 'reactstrap';
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import Loadingv3 from "../../components/Loading/Loadingv4";
import { Link, withRouter } from "react-router-dom";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";

const CustomerSupport = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [supportLink, setSupportLink] = useState('');
    useEffect(() => {
        getCustomerService();
    }, [props.auth.auth.token])

    const getCustomerService = () => {
        setLoading(true);
        fetch(api.customerService, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setSupportLink(result.redirectLink);
                    // window.open(result.redirectLink, "_blank");
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="profile language mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link className="back-icon d-inline-block px-3 py-2" to="/profile/overview">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Customer Support")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5 cursor-pointer">
                            {loading ? ( <Loadingv3 />) : (
                            <a href={supportLink ? supportLink : '#'} target="_blank">
                                <Row>
                                    <Col lg={2} md={2} sm={2} xs={2}>
                                        <FaPhoneAlt className="fa-2x text-white" />
                                    </Col>
                                    <Col lg={9} md={9} sm={9} xs={9} className="text-center">
                                        <span className={`font-weight-400 font-16 text-white`}>{translate(props.lang, "Contact Us")}</span>
                                    </Col>
                                </Row>
                            </a>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(CustomerSupport));