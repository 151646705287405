import { FaChevronLeft, FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Input } from 'reactstrap'
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import { Link, withRouter } from "react-router-dom";
import { replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";

const Profile = (props) => {
    const dispatch = useDispatch();
    const errorsObj = { username: '', email: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProfile();
        if (props.auth.profile) {
            setUsername(props.auth.profile.fullName);
            setEmail(props.auth.profile.email);
        }
    }, [props.auth.auth.token])

    const getProfile = () => {
        fetch(api.getProfile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    dispatch({
                        type: 'UPDATE_PROFILE',
                        profile: result
                    });
                    setUsername(result.fullName);
                    setEmail(result.email);
                } else if(responseJson[0] === 401){
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const editProfile = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        // let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!username) {
            errorMsg.username = "This field is required.";
            check = false;
        }
        // if (!email) {
        //     errorMsg.email = translate(props.lang, "This field is required.");
        //     check = false;
        // } else {
        //     if (!reg.test(email)) {
        //         check = false;
        //         errorMsg.email = translate(props.lang, "Invalid Email Format");
        //     }
        // }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let data = {
                "username": username,
                "email": props.auth.profile.email
            }
            fetch(api.editProfile, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
            .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        swal(translate(props.lang, 'Success'), result.message, "success");
                        getProfile();
                    } else if(responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    }else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    return (
        <>
            <div className="profile mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/profile/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Profile")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            <Row className="align-items-center">
                                <Col lg={4} md={4} sm={12} xs={12}>
                                    <div className="text-center">
                                        {props.auth.profile.profileImage ? (
                                            <img src={replaceImgUrl(props.auth.profile.profileImage)} className="img-fluid profile-img w-50" alt="profile-img" />
                                        ) : (
                                            <img src={require('../../../images/profile/empty-img.png').default} className="img-fluid profile-img w-50" alt="empty" />
                                        )}
                                    </div>
                                    <div className="mt-4 mb-1 text-center">
                                        <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>{props.auth.profile.fullName}</span>
                                    </div>
                                    <div className="text-center">
                                        <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text`}>{props.auth.profile.email}</span>
                                    </div>
                                </Col>
                                <Col lg={8} md={8} sm={12} xs={12} className="mt-lg-0 mt-md-0 mt-3">
                                    <div>
                                        <div className={`input-group ${errors.username ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                <img src={require('../../../images/icons/username.png').default} className="img-fluid icon" alt="username" />
                                            </div>
                                            <Input
                                                type="text"
                                                name="username"
                                                placeholder={translate(props.lang, "username")}
                                                className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                value={username}
                                                onChange={e => setUsername(e.target.value)}
                                            />
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.username)}</span>
                                    </div>
                                    <div className="mt-3 mb-5">
                                        <div className={`input-group ${errors.email ? 'errorBorder' : ''}`}>
                                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                <img src={require('../../../images/icons/email.png').default} className="img-fluid icon" alt="email" />
                                            </div>
                                            <Input
                                                type="text"
                                                name="email"
                                                placeholder={translate(props.lang, "email")}
                                                className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                disabled
                                            />
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.email)}</span>
                                    </div>
                                    <button className={`btn primary-btn btn-block font-weight-400 font-16`} disabled={loading} onClick={() => editProfile()}>
                                        {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                                    </button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Profile));