import React, { useContext, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import { Row, Col, Button } from 'reactstrap';
import { FaSpinner } from "react-icons/fa";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import routes from "../utils/route";
import { translate } from '../utils/translate';
import { ThemeContext } from "../context/ThemeContext";
import LiveSupport from "./components/LiveSupport";

const Markup = (props) => {
  const [passwordModal, setPasswordModal] = useState(false);
  let errorsObj = { password: '', confirmPassword: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  const closeModal = () => {
    setPasswordModal(false);
    setPassword('');
    setConfirmPassword('');
  }

  const submitPassword = () => {
    let check = true;
    const errorObj = { ...errorsObj };
    if (!password) {
      check = false;
      errorObj.password = "This field is required.";
    }
    if (!confirmPassword) {
      check = false;
      errorObj.confirmPassword = "This field is required.";
    }
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        check = false;
        errorObj.confirmPassword = "Transaction password does not match with confirm password.";
      }
    }
    setErrors(errorObj);
    if (check) {

    }
  }

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}

        <div className={`pb-5 mb-5 ${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        <LiveSupport />
        {/* {!pagePath && <Footer />} */}
        <Modal className="fade passwordModal" show={passwordModal} centered>
          <Modal.Body className="py-5 px-4">
            <div>
              <span className={`font-weight-500 font-36 text-white text-capitalize`}>{translate(props.lang, "transaction password")}</span>
            </div>
            <div className='title-border-bottom mt-2'></div>
            <div className="mt-4">
              <div className={`input-group ${errors.password ? 'errorBorder' : ''}`}>
                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                  <img src={require('../images/icons/lock.png').default} className="img-fluid w-50" />
                </div>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control input-transparent"
                  value={password}
                  placeholder={translate(props.lang, "Password")}
                  onChange={(e) =>
                    setPassword(e.target.value)
                  }
                />
                <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (<img src={require('../images/icons/hide.png').default} className="img-fluid w-50" />) : (<img src={require('../images/icons/show.png').default} className="img-fluid w-50" />)}
                </div>
              </div>
              <span className="error errorTextRed">{translate(props.lang, errors.password)}</span>
            </div>
            <div className="mt-3">
              <div className={`input-group ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                  <img src={require('../images/icons/lock-slash.png').default} className="img-fluid w-50" />
                </div>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="form-control input-transparent"
                  value={confirmPassword}
                  placeholder={translate(props.lang, "Confirm Password")}
                  onChange={(e) =>
                    setConfirmPassword(e.target.value)
                  }
                />
                <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? (<img src={require('../images/icons/hide.png').default} className="img-fluid w-50" />) : (<img src={require('../images/icons/show.png').default} className="img-fluid w-50" />)}
                </div>
              </div>
              <span className="error errorTextRed">{translate(props.lang, errors.confirmPassword)}</span>
            </div>
            <Row className="justify-content-center align-items-center mt-4">
              <Col>
                <button className={`btn cancel-btn btn-block font-weight-400 font-16`} onClick={() => closeModal()} disabled={loading}>
                  {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Cancel")}
                </button>
              </Col>
              <Col>
                <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={() => submitPassword()} disabled={loading}>
                  {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Submit")}
                </button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </div>

    </>
  );
};

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default connect(mapStateToProps)(Markup);