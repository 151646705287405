import $ from 'jquery';
import swal from 'sweetalert';
import { translate } from "./translate";
import { pathImage as url } from './api';

const copyText = (value, lang) => {
    var $temp = $("<input>");
    if (value !== '') {
        var $temp = $("<input>");
        $("body").append($temp);
        var dummy = $temp.val(value).select();
        dummy.focus();
        document.execCommand("copy");
        $temp.remove();
    }
    swal('', translate(lang, 'Copied'), "success");
}

const formatNumber = (number) => {
    if(number){
        return Number(number).toLocaleString("en-US");
    }else{
        return 0;
    }
}

const calculatePercent = (amount, target) => {
    let currentPercent = (amount / target) * 100;
    if (amount === 0) {
        return "percent-0";
    } else {
        if (currentPercent <= 10) {
            return "percent-10";
        } else if (currentPercent > 10 && currentPercent <= 20) {
            return "percent-20";
        } else if (currentPercent > 20 && currentPercent <= 30) {
            return "percent-30";
        } else if (currentPercent > 30 && currentPercent <= 40) {
            return "percent-40";
        } else if (currentPercent > 40 && currentPercent <= 50) {
            return "percent-50";
        } else if (currentPercent > 50 && currentPercent <= 60) {
            return "percent-60";
        } else if (currentPercent > 60 && currentPercent <= 70) {
            return "percent-70";
        } else if (currentPercent > 70 && currentPercent <= 80) {
            return "percent-80";
        } else if (currentPercent >= 80 && currentPercent <= 90) {
            return "percent-90";
        } else if(currentPercent >= 90){
            return "percent-95";
        }else {
            return "percent-100";
        }
    }
}

const replaceImgUrl = (imgPath) => {
    let checkUrl = "http://139.177.185.141:8081";
    if(imgPath.indexOf(checkUrl) > -1){
        imgPath = imgPath.replace(checkUrl, url);
        return imgPath;
    }else{
        return imgPath;
    }
    // return imgPath;
}

const convertToDate = (dateString) => {
    var dateParts = dateString.split("-");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 
    return dateObject.toString();
}

function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
}

export { copyText, formatNumber, calculatePercent, replaceImgUrl, convertToDate, minTwoDigits };