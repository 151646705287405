/**staging */
const url = "https://staging.starclouds.app/rest";

/**production */
// const url = "https://api.starclouds.app/rest";

const pathImage =  "https://staging.starclouds.app";

const api = {
    // register: url + '/user/signUp',
    register: url + '/user/signUpByEmail',
    login: url + '/user/authenticateEmail',
    getEmailOtp: url + '/user/email/generateEmailOtp',
    forgotPassword: url + '/user/forgetPasswordByEmail',
    getForgetPasswordOtp: url + '/user/email/generateForgetPasswordOtpByEmail',
    getReferralCode: url + '/user/referralCode',
    getCustomerService: url + '/user/customerService',
    getProfile: url + '/user/profile',
    editProfile: url + '/user/editProfile',
    userManagement: url + '/user/userManagement',
    changeLoginPassword: url + '/user/changeUserLoginPassword',
    changeTransactionPassword: url + '/user/changeUserTransactionPassword',
    deleteAccount: url + '/user/deleteAccount',
    customerService: url + '/user/customerService',
    getBankInfo: url + '/user/bankInformation',
    getIncomeMining: url + '/product/getMiningPackageHistoryList',
    getIncomeStaking: url + '/product/staking/getStakingHistoryList',
    getUserDividend: url + '/user/dividend',
    submitBankInfo: url + '/submitBankInfoPicture.php',
    getRecharge: url + '/wallet/depositList',
    withdrawHistoryList: url + '/wallet/walletWithdrawalHistoryList',
    getCommission: url + '/user/commission',
    getWithdrawalTypeList: url + '/wallet/withdrawalTypeList',
    withdraw: url + '/wallet/withdrawal',
    myBill: url + '/wallet/walletTransactionHistoryList',
    getMiningPackageList: url + '/product/getMiningPackageList',
    getMiningPackage: url + '/product/getMiningPackage?productId=:id',
    purchaseMiningPackage: url + '/product/purchaseMiningPackage',
    getStakingDetails: url + '/product/staking/getStakingDetails',
    doStake: url + '/product/staking/doStake',
    getCancelStake: url + '/product/staking/cancelStakingMessage',
    cancelStake: url + '/product/staking/cancelStaking',
    getBalance: url + '/wallet/displayBalance',
    getCountry: url + '/user/getCountry',
    generatePhoneCode: url + '/user/sms/generatePhoneNoOtp',
}

export { url, api, pathImage };