import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Table } from 'reactstrap'
import ReactPaginate from "react-paginate"
import { withRouter } from "react-router-dom";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import "../../../css/income.scss";
import { api } from "../../../utils/api";
import NoResultFound from "../Common/NoResultFound";
import Loadingv3 from "../../components/Loading/Loadingv4";
import Countdown from "../../components/Countdown/Countdown";
import { convertToDate } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";

const Mining = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const productPerpage = 10;
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [historyList, setHistoryList] = useState([]);

    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token, pageNumber])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getIncomeMining + `?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setResult(result)
                    setHistoryList(result.miningPackageHistoryList.productOrderDtoList);
                    setTotalRecord(result.miningPackageHistoryList.totalRecords);
                    let totalPage = Math.ceil(result.miningPackageHistoryList.totalRecords / productPerpage);
                    setPageCount(totalPage);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    return (
        <>
            <div className="income mining">
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={6} md={6} sm={7} xs={7}>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Investment of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={5} xs={5}>
                                    <div className="text-end">
                                        <span className={`font-weight-400 font-35 text-white letter-spacing-05px`}>{result ? result.investmentUsdt.amount.toLocaleString("en-US") : 0}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/* <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Investment of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">
                                        <span className={`font-weight-500 font-40 text-white letter-spacing-05px`}>{result ? result.investmentUsdt.amount.toLocaleString("en-US") : 0}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                    </Col>
                </Row>
                <div>
                    <Row className="justify-content-center align-items-center mt-5">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Mining Income")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Table responsive className="w-100">
                                    <thead>
                                        <tr>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Mining")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Investment Amount")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Income")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Final Income")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Purchase Date")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Termination Date")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Next Settlement")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList.map((item, i) => (
                                            <tr key={i}>
                                                <td className={`font-weight-400 font-12 w-25`}>
                                                    <div className="d-flex align-items-center">
                                                        {item.productName.toLowerCase() === 'trial' ? (
                                                            <img src={require('../../../images/icons/trial.png').default} className="img-fluid w-15" alt={item.productName} />
                                                        ) : item.productName.toLowerCase() === 'silver' ? (
                                                            <img src={require('../../../images/icons/silver.png').default} className="img-fluid w-15" alt={item.productName} />
                                                        ) : item.productName.toLowerCase() === 'gold' ? (
                                                            <img src={require('../../../images/icons/gold.png').default} className="img-fluid w-15" alt={item.productName} />
                                                        ) : item.productName.toLowerCase() === 'bronze' ? (
                                                            <img src={require('../../../images/icons/bronze.png').default} className="img-fluid w-15" alt={item.productName} />
                                                        ) : (<img src={require('../../../images/profile/empty-img.png').default} className="img-fluid w-15" alt={item.productName} />)}
                                                        
                                                        <span className={`font-weight-400 font-12 text-uppercase text-white ms-3`}>{item.productName}</span>
                                                    </div>
                                                </td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-uppercase primary-text`}>{item.packagePrice} {item.tradingPair}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.income} {item.tradingPair}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.finalIncome}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.startDate}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.endDate}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>
                                                    <Countdown data={convertToDate(item.endDate)} />

                                                </td>
                                            </tr>
                                        ))}
                                        {totalRecord === 0 && !loading ? (
                                            <tr>
                                                <td colspan={7}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                        {totalRecord > 0 ? (
                            <div>
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"pagination font-weight-400 font-16 text-uppercase text-blue-1"}
                                    previousLinkClassName={"pagination__previouslink text-blue-1"}
                                    nextLinkClassName={"pagination__nextlink text-blue-1"}
                                    disabledClassName={"pagination__link--disabled text-muted"}
                                    activeClassName={"pagination__link--active text-white"}
                                />
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Mining));