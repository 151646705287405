import { FaChevronRight, FaSpinner } from 'react-icons/fa';
import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import swal from 'sweetalert';
import { Row, Col } from 'reactstrap';
import { api } from '../../../../utils/api';
import { translate } from "../../../../utils/translate";
import { logout } from '../../../../store/actions/AuthActions';
import Loadingv3 from '../../../components/Loading/Loadingv4';
import stakingIcon from '../../../../images/home/staking-icon.png';

const stakingDummyData = [
    { stakingImg: stakingIcon, staking: "Staking USDT", dailyIncomeRate: "0.50%", cancelFees: "3%", earn: "USDT", stakingAmount: "500.00", currentSA: "0" },
    { stakingImg: stakingIcon, staking: "Staking USDT", dailyIncomeRate: "0.50%", cancelFees: "3%", earn: "USDT", stakingAmount: "500.00", currentSA: "0" },
    { stakingImg: stakingIcon, staking: "Staking USDT", dailyIncomeRate: "0.50%", cancelFees: "3%", earn: "USDT", stakingAmount: "500.00", currentSA: "0" },
]

const Staking = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [stakingData, setStakingData] = useState({
        stakingDailyPayout: null,
        stakingDetails: null,
    });
    useEffect(() => {
        getInfo();
        // getCancelStakingMsg();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getStakingDetails, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setStakingData(result);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    // const getCancelStakingMsg = () => {
    //     fetch(api.getCancelStake, {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json",
    //             "Content-Language": props.lang,
    //             "Authorization": "Bearer " + props.auth.auth.token
    //         }),
    //     })
    //         .then(response => Promise.all([response.status, response.text()]))
    //         //         .then(response => Promise.all([response.ok, response.text()]))
    //         .then(responseJson => {
    //             setLoading(false);
    //             let result = JSON.parse(responseJson[1]);
    //             if (responseJson[0] === 200) {
    //                 console.log("getCancelStakingMsg", result)
    //             } else if (responseJson[0] === 401) {
    //                 dispatch(logout(props.history, 'login'));
    //             } else {
    //                 swal(translate(props.lang, 'Error'), result.message, "error");
    //             }
    //         }).catch(error => {
    //             console.error("error", error);
    //         });
    // }

    const cancelStaking = () => {
        swal({
            title: translate(props.lang, "Are you sure to cancel staking?"),
            text: translate(props.lang, "Once confirm, you cant revert this action anymore"),
            icon: "warning",
            buttons: [translate(props.lang, "No"), translate(props.lang, "Yes")],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    fetch(api.cancelStake, {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            "Content-Language": props.lang.toLowerCase(),
                            "Authorization": "Bearer " + props.auth.auth.token
                        }),
                    })
                        .then(response => Promise.all([response.status, response.text()]))
                        // .then(response => Promise.all([response.ok, response.text()]))
                        .then(responseJson => {
                            setLoading(false);
                            let result = JSON.parse(responseJson[1]);
                            if (responseJson[0] === 200) {
                                swal(translate(props.lang, 'Success'), result.message, "success");
                            } else if (responseJson[0] === 401) {
                                dispatch(logout(props.history, 'login'));
                            } else {
                                swal(translate(props.lang, 'Error'), result.message, "error");
                            }
                        }).catch(error => {
                            console.error("error", error);
                        });
                }
            });
    }

    console.log("stakingData", stakingData);

    return (
        <>
            <div className="home overview">
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            {loading ? (<Loadingv3 />) : (
                                <Row className="justify-content-center align-items-center">
                                    <Col lg={6} md={6} sm={7} xs={7}>
                                        <div className="position-relative d-flex h-100">
                                            <img src={require('../../../../images/commission/commission.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                            <div className="balance-text-container d-flex align-items-center">
                                                <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{stakingData.stakingDailyPayout ? stakingData.stakingDailyPayout.title : ''}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={5} xs={5}>
                                        <div className="text-end">
                                            <span className={`font-weight-400 font-35 text-white letter-spacing-05px`}>{stakingData.stakingDailyPayout ? stakingData.stakingDailyPayout.dailyPayoutAmount : ''}</span>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
                <div className='mt-4'>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Staking Income")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={6} md={6} sm={12} xs={12}>
                        {loading ? (
                            <div className="card card-black-2 px-4 py-4">
                                <Loadingv3 />
                            </div>
                        ) : (
                            <div className="card card-black-2 px-4 py-4">
                                <div className='d-flex align-items-center w-100'>
                                    <img src={require('../../../../images/profile/empty-img.png').default} className="w-15" alt="package" />
                                    <span className={`mx-2 font-weight-400 font-20 text-white letter-spacing-05px d-block mb-1 w-75`}>{stakingData.stakingDetails ? stakingData.stakingDetails.stakingName : ''}</span>
                                </div>
                                <hr />
                                <div className='mb-3 d-flex w-100'>
                                    <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1 w-50`}>{translate(props.lang, "Daily Income Rate")}</span>
                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block w-50 text-end`}>{stakingData.stakingDetails ? Number(stakingData.stakingDetails.dailyRatePercentage).toFixed(2) + '%' : ''}</span>
                                </div>
                                <div className='mb-3 d-flex w-100'>
                                    <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1 w-50`}>{translate(props.lang, "Cancel Fees")}</span>
                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block w-50 text-end`}>{stakingData.stakingDetails ? Number(stakingData.stakingDetails.cancelProcessingFee).toFixed(2) + '%' : ''}</span>
                                </div>
                                <div className='mb-3 d-flex w-100'>
                                    <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1 w-50`}>{translate(props.lang, "Earn")}</span>
                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block w-50 text-end`}>{stakingData.stakingDetails ? stakingData.stakingDetails.earnType : ''}</span>
                                </div>
                                <div className='mb-3 d-flex w-100'>
                                    <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1 w-50`}>{translate(props.lang, "Staking Amount")}</span>
                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block w-50 text-end`}>{stakingData.stakingDetails ? stakingData.stakingDetails.maxAmount : ''}</span>
                                </div>
                                <div className='mb-3 d-flex w-100'>
                                    <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1 w-50`}>{translate(props.lang, "Current Stake Amount")}</span>
                                    <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block w-50 text-end`}>{stakingData.stakingDetails ? stakingData.stakingDetails.currentStakingAmount : ''}</span>
                                </div>
                                <hr />
                                <div className='d-flex justify-content-end h-auto w-100'>
                                    <div onClick={() => cancelStaking()} className={`btn staking-cancel-btn font-weight-500 d-block font-13 text-capitalize px-2 w-50`}>
                                        {translate(props.lang, "Cancel")}
                                    </div>
                                    <Link to={"/home/staking"} className={`btn d-block w-50 primary-btn ms-2 px-2 font-weight-500 font-13 text-capitalize`}>
                                        {translate(props.lang, "Staking")}
                                    </Link>
                                </div>

                        {/* {loading ? (
                            <div className="card card-black-2 px-4 py-4">
                                <Loadingv3 />
                            </div>
                        ) : (
                            <div className='table-responsive'>
                                <table className="table text-white" style={{ overflow: "scroll" }}>
                                    <thead >
                                        <tr>
                                            <th className="font-13 font-weight-400 text-center" scope="col">STAKING</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col">Daily Income Rate</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col">Cancel Fees</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col">Earn</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col">Staking Amount</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col">Current Stake Amount</th>
                                            <th className="font-13 font-weight-400 text-center" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            stakingData && stakingData.stakingDetails ?
                                                stakingData.stakingDetails.map((option, i) => (
                                                    <tr key={i} className="py-2 text-center">
                                                        <td>
                                                            <div className="d-flex justify-content-center align-items-center">
                                                                <img src={option.stakingImg} alt="" className="stakeTableImg" />
                                                                <div className="mx-3">{option.stakingName}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {option.dailyRatePercentage}
                                                        </td>
                                                        <td>
                                                            {option.cancelProcessingFee}
                                                        </td>
                                                        <td>
                                                            {option.earnType}
                                                        </td>
                                                        <td>
                                                            {option.totalStakingAmount}
                                                        </td>
                                                        <td>
                                                            {option.currentStakingAmount}
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-end h-auto w-100'>
                                                                <div onClick={() => cancelStaking()} className={`btn staking-cancel-btn font-weight-500 d-block font-13 text-capitalize px-2 w-50`}>
                                                                    {translate(props.lang, "Cancel")}
                                                                </div>
                                                                <Link to={"/home/staking"} className={`btn d-block w-50 primary-btn ms-2 px-2 font-weight-500 font-13 text-capitalize`}>
                                                                    {translate(props.lang, "Staking")}
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : ""
                                        }
                                    </tbody>
                                </table> */}

                                {/* <Table responsive className="w-100">
                                <thead>
                                    <tr>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Staking")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Daily Income Rate")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Cancel Fees")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Earn")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Staking Amount")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Current Stake Amount")}</th>
                                        <th className={`font-weight-400 font-13 text-capitalize secondary-text`}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.map((item, i) => (
                                        <tr key={i}>
                                            <td className={`font-weight-400 font-12 w-25`}>
                                                <div className="d-flex align-items-center">
                                                    <img src={require('../../../../images/income/starcloud.png').default} className="img-fluid w-15 starcoin" alt={item.staking} />
                                                    <span className={`font-weight-400 font-12 text-uppercase text-white ms-3`}>{item.staking}</span>
                                                </div>
                                            </td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-uppercase primary-text`}>{item.dailyIncomeRate}</td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.cancelFee}</td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.earn}</td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.stakingAmount}</td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{item.currentAmount}</td>
                                            <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>
                                                <div className='d-flex h-auto w-100'>
                                                    <div className={`btn cancel-btn font-weight-500 font-13 text-capitalize px-2`}>
                                                        {translate(props.lang, "Cancel")}
                                                    </div>
                                                    <Link to={"/home/staking/1"} className={`btn primary-btn ms-2 px-2 font-weight-500 font-13 text-capitalize`}>
                                                        {translate(props.lang, "staking")}
                                                    </Link>
                                                </div>
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table> */}
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Staking));