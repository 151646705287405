import { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap'
import { Row, Col } from 'reactstrap';
import { logout } from '../../../store/actions/AuthActions';
import swal from 'sweetalert';
import { translate } from "../../../utils/translate";
import Mining from './Overview/Mining';
import Staking from './Overview/Staking';
import { api } from '../../../utils/api';
import { replaceImgUrl } from '../../../utils/function';
import Loadingv3 from '../../components/Loading/Loadingv4';

const quicklink = [
    { name: 'Recharge', img: require('../../../images/home/recharge.png').default, path: '/home/recharge' },
    { name: 'Withdrawal', img: require('../../../images/home/withdrawal.png').default, path: '/home/withdrawal' },
    { name: 'My Bill', img: require('../../../images/home/bill.png').default, path: '/home/my-bill' },
    { name: 'Commission', img: require('../../../images/home/commission.png').default, path: '/home/commission' },
]

const HomeOverview = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(1);
    const [banner, setBanner] = useState([])
    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getMiningPackageList, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            // .then(response => Promise.all([response.ok, response.text()]))
            // .then(responseJson => {
            //     setLoading(false);
            //     let result = JSON.parse(responseJson[1]);
            //     if (responseJson[0]) {
            //         setBanner(result.bannerUrl);
            //     } else {
            //         swal(translate(props.lang, 'Error'), result.message, "error");
            //     }
            // }).catch(error => {
            //     console.error("error", error);
            // });
            .then(response => Promise.all([response.status, response.text()]))
            .then(responseJson => {
                setLoading(false);

                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else if (responseJson[0] === 200) {
                    setBanner(result.bannerUrl);
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="home overview mt-4 mt-lg-0">
                {loading ? (
                    <div className="card card-black-2 px-4 py-5">
                        <Loadingv3 />
                    </div>
                ) : (
                    <div>
                        <Carousel indicators>
                            {banner.map((carousel, i) => (
                                <Carousel.Item key={i}>
                                    <img
                                        src={replaceImgUrl(carousel.imageUrl)}
                                        className='d-block banner'
                                        alt={`Slide ${i + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                )}
                {/* <div className="quicklink">
                    <Row className="mt-3">
                        {quicklink.map((item, i) => (
                            <Col lg={3} md={3} sm={6} xs={6} key={i} className="mb-3">
                                <Link className="d-flex align-items-center w-100 quicklink-item" to={item.path}>
                                    <div className="quicklink-icon-container">
                                        <img src={item.img} className="quicklink-icon" alt={item.name} />
                                    </div>
                                    <div className="mx-2">
                                        <span className={`font-weight-400 font-16 text-white`}>{translate(props.lang, item.name)}</span>
                                    </div>
                                </Link>
                            </Col>
                        ))}
                    </Row>
                </div> */}
                <div className="d-flex mt-3">
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 1 ? 'active' : ""}`} onClick={() => setTab(1)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Mining")}</span>
                    </div>
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 2 ? 'active' : ""}`} onClick={() => setTab(2)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Staking")}</span>
                    </div>
                </div>
                {tab === 1 ? (<Mining />) : (<Staking />)}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(HomeOverview));