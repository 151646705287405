import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaAngleDown } from "react-icons/fa";
/// Image
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import LogoutPage from './Logout';

const lang = [
    { name: 'English', code: 'EN' },
    { name: '中文（简体）', code: 'ZH' },
    // { name: 'Japanese', code: 'JA' },
    // { name: 'Korean', code: 'KR' },
    // { name: 'Vietnamese', code: 'VI' },
]

const Header = (props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	var path = window.location.pathname.split("/");
	var name = path[path.length - 1].split("-");

	const changeLanguage = (language) => {
		dispatch({
			type: 'UPDATE_LANGUAGE',
			lang: language
		});
		localStorage.setItem('starcloud-lang', language);
	}

	return (
		<div className="header">
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between header-right-container">
						<div className="header-left">
							<div className="dashboard_bar text-capitalize"></div>
						</div>
						<ul className="navbar-nav header-right main-notification">
							<li className="nav-item d-none d-lg-flex">
								<div className="lang-dropdown-container position-relative">
									<label htmlFor="openDropdown-lg" className="lang-dropdown dropdown px-3 py-2 mb-0 mt-1">
										<span className={`px-2 font-weight-400 font-13`}>{lang.filter(item => item.code === props.lang)[0].name}</span>
										<FaAngleDown className="fa-angle-down-lg" />
									</label>
									<input type="checkbox" id="openDropdown-lg" hidden />
									<div className={`lang-dropdown-menu-lg`}>
										<div className="lang-menu-container text-center w-100">
											{lang.map((item, i) => (
												<span key={i} className={`font-weight-500 font-12`} onClick={() => changeLanguage(item.code)}>{item.name}</span>
											))}
										</div>
									</div>
								</div>
							</li>
							{/* <Dropdown as="li" className="nav-item notification_dropdown ">
								<Dropdown.Toggle variant="" as="a" className="nav-link  ai-icon i-false c-pointer">
									<img src={require('../../../images/header/bell.png').default} className="bell" alt="notification" />
								</Dropdown.Toggle>
								<Dropdown.Menu align="right" className="mt-4 dropdown-menu dropdown-menu-end">
									<PerfectScrollbar className="widget-media dz-scroll p-3 height380">
										<ul className="timeline">
											<li>
												<div className="timeline-panel">
													<div className="media me-2">
														<img alt="images" width={50} src={avatar} />
													</div>
													<div className="media-body">
														<h6 className="mb-1">Dr sultads Send you Photo</h6>
														<small className="d-block">29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
											<li>
												<div className="timeline-panel">
													<div className="media me-2 media-info">KG</div>
													<div className="media-body">
														<h6 className="mb-1">Resport created successfully</h6>
														<small className="d-block">29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
											<li>
												<div className="timeline-panel">
													<div className="media me-2 media-success">
														<i className="fa fa-home" />
													</div>
													<div className="media-body">
														<h6 className="mb-1">Reminder : Treatment Time!</h6>
														<small className="d-block">29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
											<li>
												<div className="timeline-panel">
													<div className="media me-2">
														<img alt="" width={50} src={avatar} />
													</div>
													<div className="media-body">
														<h6 className="mb-1">Dr sultads Send you Photo</h6>
														<small className="d-block">29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
											<li>
												<div className="timeline-panel">
													<div className="media me-2 media-danger">KG</div>
													<div className="media-body">
														<h6 className="mb-1">Resport created successfully</h6>
														<small className="d-block">29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
											<li>
												<div className="timeline-panel">
													<div className="media me-2 media-primary">
														<i className="fa fa-home" />
													</div>
													<div className="media-body">
														<h6 className="mb-1">Reminder : Treatment Time!</h6>
														<small className="d-block"> 29 July 2020 - 02:26 PM</small>
													</div>
												</div>
											</li>
										</ul>
										<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
											<div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
										</div>
										<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
											<div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
										</div>
									</PerfectScrollbar>
									<Link className="all-notification" to="#">
										See all notifications <i className="ti-arrow-right" />
									</Link>
								</Dropdown.Menu>
							</Dropdown> */}
							
							{/* <li className="nav-item">
								<div className="profile-dropdown-container position-relative">
									<label htmlFor="openProfile-lg" className="profile-dropdown dropdown px-3 py-2 mb-0 mt-1">
										<img src={profile} className="profile-img" />
									</label>
									<input type="checkbox" id="openProfile-lg" hidden />
									<div className={`profile-dropdown-menu-lg`}>
										
									</div>
								</div>
							</li> */}
							<Dropdown as="li" className="nav-item dropdown header-profile">
								<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
									<img src={require('../../../images/profile/empty-img.png').default} width={20} className="rounded" alt="profile" />
								</Dropdown.Toggle>

								<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
									{/* <Link to="/app-profile" className="dropdown-item ai-icon">
										<svg
											id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary"
											width={18} height={18} viewBox="0 0 24 24" fill="none"
											stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
										>
											<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
											<circle cx={12} cy={7} r={4} />
										</svg>
										<span className="ms-2">Profile </span>
									</Link> */}
									{/* <Link to="/email-inbox" className="dropdown-item ai-icon">
										<svg
											id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width={18}
											height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2}
											strokeLinecap="round" strokeLinejoin="round"
										>
											<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
											<polyline points="22,6 12,13 2,6" />
										</svg>
										<span className="ms-2">Inbox </span>
									</Link> */}
									<LogoutPage />
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { auth, i18n } = state;
	return {
		lang: i18n.lang,
		auth: auth,
	};
};

export default connect(mapStateToProps)(Header);
