import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import swal from "sweetalert";
import { Link, withRouter } from "react-router-dom";
import { FaChevronLeft, FaRegCopy } from "react-icons/fa";
import { translate } from "../../../utils/translate";
import { api } from "../../../utils/api";
import { copyText, formatNumber, replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";
import Loadingv3 from "../../components/Loading/Loadingv4";

const Recharge = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [rechargeInfo, setRechargeInfo] = useState({
        depositQRCodeList: [],
        walletBalanceDtoList: []
    })
    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true);
        fetch(api.getRecharge, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setRechargeInfo(result)
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="profile recharge mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/home/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Recharge")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={6} md={6} sm={7} xs={7}>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Balance of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={5} xs={5}>
                                    <div className="text-end">
                                        <span className={`font-weight-400 font-35 text-white letter-spacing-05px`}>{rechargeInfo.walletBalanceDtoList.length > 0 ? formatNumber(rechargeInfo.walletBalanceDtoList.filter(i => i.walletType === 'USDT')[0].walletBalance) : 0}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {loading ? (
                    <div className="card card-black px-4 py-4">
                        <Loadingv3 />
                    </div>
                ) : (
                    <Row className="justify-content-start mt-4 display-flex1">
                        {rechargeInfo.depositQRCodeList.map((item, i) => (
                            <Col lg={6} md={6} sm={12} xs={12} className="display-flex-col1" key={i}>
                                <div className="card qr-card-bg px-4 py-4 h-100">
                                    <div className="text-center d-flex justify-content-center">
                                        <div className="w-50">
                                            {/* <QRCode
                                        id="erc20"
                                        size={128}
                                        fgColor="#000000"
                                        bgColor="#ffffff"
                                        className="h-100 w-100 p-2"
                                        value={item.walletAddress}
                                        viewBox={`0 0 128 128`}
                                    /> */}
                                            <img src={replaceImgUrl(item.iconUrl)} className="h-100 w-100 wallet-qr-container" />
                                        </div>
                                    </div>
                                    <div className="text-center my-3">
                                        <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>{item.walletType}</span>
                                    </div>
                                    <div className={`input-group py-3 px-4 w-100`}>
                                        <div className="w-75">
                                            <span className="font-weight-400 font-14 secondary-text letter-spacing-05px">{item.walletAddress}</span>
                                        </div>
                                        <div className='w-25 d-flex align-items-center justify-content-end transparent-bg text-end' onClick={() => copyText(item.walletAddress, props.lang)}>
                                            <FaRegCopy />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                        {/* <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            <div className="text-center d-flex justify-content-center">
                                <div className="wallet-qr-container p-3 w-50">
                                    <QRCode
                                        id="erc20"
                                        size={128}
                                        fgColor="#000000"
                                        bgColor="#ffffff"
                                        className="h-100 w-100 p-2"
                                        value={'AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw'}
                                        viewBox={`0 0 128 128`}
                                    />
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>ERC20</span>
                            </div>
                            <div className={`input-group py-3 px-4 w-100`}>
                                <div className="w-75">
                                    <span className="font-weight-400 font-14 secondary-text letter-spacing-05px">{'AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw'}</span>
                                </div>
                                <div className='w-25 d-flex align-items-center justify-content-end transparent-bg text-end' onClick={() => copyERC('AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw')}>
                                    <FaRegCopy />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            <div className="text-center d-flex justify-content-center">
                                <div className="wallet-qr-container p-3 w-50">
                                    <QRCode
                                        id="erc20"
                                        size={128}
                                        fgColor="#000000"
                                        bgColor="#ffffff"
                                        className="h-100 w-100 p-2"
                                        value={'AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw'}
                                        viewBox={`0 0 128 128`}
                                    />
                                </div>
                            </div>
                            <div className="text-center my-3">
                                <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>TRC20</span>
                            </div>
                            <div className={`input-group py-3 px-4 w-100`}>
                                <div className="w-75">
                                    <span className="font-weight-400 font-14 secondary-text letter-spacing-05px">{'AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw'}</span>
                                </div>
                                <div className='w-25 d-flex align-items-center justify-content-end transparent-bg text-end' onClick={() => copyTRC('AKdnwo13489XNEO8Cni2378hfahSDVSW&YDFGBSDASVBHGNJK#WDviw')}>
                                    <FaRegCopy />
                                </div>
                            </div>
                        </div>
                    </Col> */}
                    </Row>
                )}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Recharge));