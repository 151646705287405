import { connect } from 'react-redux';
import "../../../css/loading.scss";
import { translate } from "../../../utils/translate";

const Loadingv4 = (props) => {
    return (
        <div className="loading4">
            <div className="preloader-container py-4">
                <div className="text-center d-flex align-items-center justify-content-center w-100">
                    <div className="hourglass">
                        <div className="two"></div>
                    </div>
                </div>
                <div className="mt-2 text-center">
                    <span className="loading-text">{translate(props.lang, "Loading Data")}</span>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        errorMessage: auth.errorMessage,
        successMessage: auth.successMessage,
        showLoading: auth.showLoading,
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(Loadingv4);