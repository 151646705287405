import { connect } from "react-redux";
import { useState } from 'react';
import { translate } from "../../../utils/translate";
import "../../../css/income.scss";
import Mining from "./Mining";
import Staking from "./Staking";
const Income = (props) => {
    const [tab, setTab] = useState(1);
    return (
        <>
            <div className="income mt-4 mt-lg-0 mt-md-2">
                <div className="d-flex">
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 1 ? 'active': ""}`} onClick={()=>setTab(1)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Mining")}</span>
                    </div>
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 2 ? 'active': ""}`} onClick={()=>setTab(2)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Staking")}</span>
                    </div>
                </div>
                
                <div className="mt-4">
                    {tab === 1 ? (
                        <Mining />
                    ) : (
                        <Staking />
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Income);