import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { translate } from "../../../utils/translate";

const NoResultFound = (props) => {
    return (
        <div className="bg-transparent">
            <div className="container h-100">
                <Row className="row justify-content-center h-100 align-items-center">
                    <Col lg={10} md={10} sm={10} xs={10} className="text-center py-5">
                        <div className="text-center mb-4">
                            <img src={require('../../../images/common/no-result.png').default} className="img-fluid w-50" alt="no-result" />
                        </div>
                        <div className="mb-2">
                            <span className={`font-weight-400 font-16 text-white`}>{translate(props.lang, "No Results Found")}</span>
                        </div>
                        <div className="mb-4">
                            <span className={`font-weight-400 font-13 secondary-text`}> {translate(props.lang, "There’s nothing for now")}</span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(NoResultFound);