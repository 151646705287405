import { connect } from 'react-redux';
import "../../../css/loading.scss";
import { translate } from "../../../utils/translate";

const Loadingv1 = (props) => {
    return (
        <div className="loading-container loading1">
            <div className="preloader-2">
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
                <span className="line line-4"></span>
                <span className="line line-5"></span>
                <span className="line line-6"></span>
                <span className="line line-7"></span>
                <span className="line line-8"></span>
                <span className="line line-9"></span>
            </div>
            <div className={`font-weight-400 font-16 loading-text mt-3`}>{translate(props.lang, "Loading")}</div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { auth, i18n } = state;
    return {
        errorMessage: auth.errorMessage,
        successMessage: auth.successMessage,
        showLoading: auth.showLoading,
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(Loadingv1);