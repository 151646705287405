import { FaChevronLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Row, Col } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/home.scss";
import { Link, withRouter } from "react-router-dom";
import { api } from "../../../utils/api";
import { formatNumber, calculatePercent, replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";
import Loadingv3 from "../../components/Loading/Loadingv4";

const Commission = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [commissionInfo, setCommissionInfo] = useState({
        dailyEarn: 0,
        numberOfFriend: 0,
        totalCommision: 0,
        invitedList: [],
    });

    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getCommission, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setCommissionInfo({
                        dailyEarn: result.dailyEarn,
                        numberOfFriend: result.numberOfFriend,
                        totalCommision: result.totalCommision,
                        invitedList: result.invitedList,
                    })
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }
    return (
        <>
            <div className="home commission mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-start align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/home/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Commission")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-3 py-md-3 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-end">
                                <Col lg={6} md={6} sm={12} xs={12}>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/commission/commission.png').default} className="img-fluid commission-icon" alt="commission" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <div className="">
                                                <span className={`font-weight-400 font-18 text-white letter-spacing-05px d-block`}>{translate(props.lang, "Total Commission")}</span><br/>
                                                <span className={`font-weight-500 font-40 text-white letter-spacing-05px ms-3`}>{formatNumber(commissionInfo.totalCommision)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xs={12} sm={12} className="mt-lg-0 mt-md-0 mt-3">
                                    <div className="d-flex align-items-center justify-content-start align-items-lg-end justify-content-lg-end h-100">
                                        <div className="col1">
                                            <span className={`font-weight-400 font-13 text-white letter-spacing-05px pe-4`}>{translate(props.lang, "Number of Friends")}</span>
                                            <span className={`font-weight-600 font-13 text-white letter-spacing-05px`}>{commissionInfo.numberOfFriend}</span>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="vertical-line mx-4"></div>
                                        </div>
                                        <div>
                                            <span className={`font-weight-400 font-13 text-white letter-spacing-05px pe-4`}>{translate(props.lang, "Daily Income")}</span>
                                            <span className={`font-weight-600 font-13 text-white letter-spacing-05px`}>{formatNumber(commissionInfo.dailyEarn)}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                {loading ? (
                    <div className="card qr-card-bg px-4 py-4">
                        <Loadingv3 />
                    </div>
                ) : (
                    <Row className="mt-5">
                        {commissionInfo.invitedList.map((item, i) => (
                            <Col lg={4} md={4} sm={12} xs={12}>
                                <div className="card qr-card-bg px-4 py-4">
                                    <div>
                                        <img src={replaceImgUrl(item.imageUrl)} className="img-fluid w-25" alt={'level'} />
                                    </div>
                                    <div className="mt-3 mb-2">
                                        <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{item.mission}</span>
                                    </div>
                                    <div className="progress2 progress-moved position-relative">
                                        <div className={`progress-bar2 progress-with-text  ${calculatePercent(item.totalDirectDownline, item.targetDownline)}`} >
                                        </div>
                                        <span className={`font-weight-300 font-8 letter-spacing-003em text-white percentage`}>{item.totalDirectDownline + "/" + item.targetDownline}</span>
                                    </div>
                                    <div className="mt-3 mt-lg-2">
                                        <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>{item.invitedReward}</span>
                                    </div>
                                </div>
                            </Col>
                        ))}

                        {/* <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card card-black px-4 py-4">
                            <div>
                                <img src={require('../../../images/dividend/lv1.png').default} className="img-fluid w-25" alt={'level'} />
                            </div>
                            <div className="mt-3 mb-2">
                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, 'Invite 10 friends to rent mining machine')}</span>
                            </div>
                            <div className="progress2 progress-moved">
                                <div className="progress-bar2 progress-without-text" >
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>+10 USDT</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <div className="card card-black px-4 py-4">
                            <div>
                                <img src={require('../../../images/dividend/lv1.png').default} className="img-fluid w-25" alt={'level'} />
                            </div>
                            <div className="mt-3 mb-2">
                                <span className={`font-weight-400 font-16 letter-spacing-05px text-white`}>{translate(props.lang, 'Invite 15 friends to rent mining machine')}</span>
                            </div>
                            <div className="progress2 progress-moved">
                                <div className="progress-bar2 progress-without-text" >
                                </div>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>+15 USDT</span>
                            </div>
                        </div>
                    </Col> */}
                    </Row>
                )}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Commission));