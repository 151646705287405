import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { Row, Col, Table } from 'reactstrap';
import { Link, withRouter } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { translate } from "../../../utils/translate";
import "../../../css/home.scss";
import { api } from "../../../utils/api";
import NoResultFound from "../Common/NoResultFound";
import { formatNumber } from "../../../utils/function";
import Loadingv3 from "../../components/Loading/Loadingv4";
import { logout } from "../../../store/actions/AuthActions";
import moment from "moment";

const Withdrawal = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const productPerpage = 5;
    const [totalRecord, setTotalRecord] = useState(0);
    const [result, setResult] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [historyList, setHistoryList] = useState([]);

    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token, pageNumber])

    const getInfo = () => {
        setLoading(true)
        fetch(api.withdrawHistoryList + `?pageNo=${pageNumber}&pageSize=${productPerpage}`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setResult(result)
                    setHistoryList(result.withdrawalHistoryList.walletWithdrawalDtoList);
                    setTotalRecord(result.withdrawalHistoryList.totalRecords);
                    let totalPage = Math.ceil(result.withdrawalHistoryList.totalRecords / productPerpage);
                    setPageCount(totalPage);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="profile withdrawal mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/home/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Withdrawal")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-5">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Balance of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">
                                        <span className={`font-weight-400 font-35 text-white letter-spacing-05px`}>{result ? formatNumber(result.usdtBalance) : 0}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="mt-3">
                    <Row className="justify-content-center align-items-center">
                        <Col lg={8} md={8} sm={8} xs={8} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Withdrawal History")}</span>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={4} className="text-end">
                            <Link to="/home/withdrawal/history" className="cursor-pointer">
                                <span className={`font-weight-400 font-14 text-white`}>{translate(props.lang, "View More")}</span>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card qr-card-bg px-4 py-5">
                            {loading ? (
                                <Loadingv3 />
                            ) : (
                                <Table responsive className="w-100">
                                    <thead>
                                        <tr>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Reference no")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Time")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Withdrawal")}</th>
                                            <th className={`font-weight-400 font-13 text-capitalize secondary-text`}>{translate(props.lang, "Status")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {historyList.map((item, i) => (
                                            <tr key={i}>
                                                <td className={`font-weight-500 font-13 letter-spacing-05px text-capitalize`}>{item.description}</td>
                                                <td className={`font-weight-400 font-13 letter-spacing-05px text-white`}>{moment(item.datetimeAdd).format('DD-MM-YYYY HH:MM:ss')}</td>
                                                <td className={`font-weight-600 font-13 letter-spacing-05px primary-text`}>{item.amountString}</td>
                                                <td>
                                                    <div className={`withdraw-status ${item.status.toLowerCase()} px-3 py-2`}>
                                                        {translate(props.lang, item.status.toLowerCase())}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {totalRecord === 0 && !loading ? (
                                            <tr>
                                                <td colSpan={4}>
                                                    <NoResultFound />
                                                </td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </Table>
                            )}
                        </div>
                    </Col>
                </Row>
                <Link to="/home/withdrawal/request" className={`my-5 btn primary-btn btn-block font-weight-400 font-16`}>
                    {translate(props.lang, "Withdrawal")}
                </Link>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Withdrawal));