import { FaChevronRight } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from 'reactstrap'
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { Link, withRouter } from "react-router-dom";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { logout } from "../../../store/actions/AuthActions";
import { replaceImgUrl } from "../../../utils/function";

const menu = [
    { name: 'Profile', img: require('../../../images/profile/profile.png').default, path: '/profile' },
    { name: 'User Management', img: require('../../../images/profile/user-management.png').default, path: '/profile/user-management' },
    { name: 'Settings', img: require('../../../images/profile/settings.png').default, path: '/profile/settings' },
    { name: 'Bank Information', img: require('../../../images/profile/bank-information.png').default, path: '/profile/bank-information' },
    { name: 'My Referral Code', img: require('../../../images/profile/referral-code.png').default, path: '/profile/referral-code' },
    // { name: 'Customer Support', img: require('../../../images/profile/customer-support.png').default, path: '/profile/customer-support' },
]

const ProfileOverview = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getProfile();
    }, [props.auth.auth.token])

    const getProfile = () => {
        fetch(api.getProfile, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
        .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    dispatch({
                        type: 'UPDATE_PROFILE',
                        profile: result
                    });
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    return (
        <>
            <div className="profile mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-start align-items-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-start">
                            <div>
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Profile")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} xs={12} sm={12}>
                        <div className="card card-black-2 px-4 py-3 cursor-pointer">
                            <Row>
                                <Col lg={2} md={2} sm={12} xs={12}>
                                    <div className="">
                                        {props.auth.profile.profileImage ? (
                                            <img src={replaceImgUrl(props.auth.profile.profileImage)} className="img-fluid profile-img" alt="profile-img" />
                                        ) : (
                                            <img src={require('../../../images/profile/empty-img.png').default} className="img-fluid w-50" alt="empty" />
                                        )}
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6} className="mt-lg-0 mt-md-0 mt-3">
                                    <div className="h-100 d-flex flex-column justify-content-center w-100">
                                        <div>
                                            <span className={`font-weight-500 font-20 letter-spacing-05px text-white`}>{props.auth.profile.fullName}</span>
                                        </div>
                                        <div>
                                            <span className={`font-weight-400 font-16 letter-spacing-05px secondary-text`}>{props.auth.profile.email}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <div className="d-flex align-items-center h-100 justify-content-end w-100">
                                        <div className="w-100 d-flex ustify-content-end">
                                            <div className="position-relative w-100 text-end">
                                                {props.auth.profile.managerLevelLogo ? (
                                                    <img src={replaceImgUrl(props.auth.profile.managerLevelLogo)} className="rank-img" alt="profile-img" />
                                                ) : null}
                                                <div className={`rank-container ps-3 pe-4 py-3 text-end d-inline-block`}>
                                                    <span className={`font-weight-400 font-18 text-white`}>{props.auth.profile.managerLevel}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} xs={12} sm={12}>
                    <div className="card card-green-gradient px-4 py-lg-5 py-md-5 py-3 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={6} md={6} sm={7} xs={7}>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Balance of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={5} xs={5}>
                                    <div className="text-end">
                                        <span className={`font-weight-400 font-35 text-white letter-spacing-05px`}>{Number(props.auth.profile.usdtBalance).toLocaleString("en-US")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {/* <div className="card missionLevelBg px-4 py-5 cursor-pointer">
                            <Row className="justify-content-center align-items-center">
                                <Col>
                                    <div className="position-relative d-flex h-100">
                                        <img src={require('../../../images/profile/usdt.png').default} className="img-fluid usdt-icon" alt="usdt" />
                                        <div className="balance-text-container d-flex align-items-center">
                                            <span className={`font-weight-500 font-20 text-white letter-spacing-05px`}>{translate(props.lang, "Balance of USDT")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-end">
                                        <span className={`font-weight-500 font-40 text-white letter-spacing-05px`}>{Number(props.auth.profile.usdtBalance).toLocaleString("en-US")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                    </Col>
                </Row>
                <Row className="justify-content-start align-items-center mt-4">
                    {menu.map((item, i) => (
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <Link className="card profile-iconContainerBg px-4 py-5 cursor-pointer" to={item.path}>
                                <Row className="justify-content-center align-items-center">
                                    <Col lg={4} md={4} sm={3} xs={3}>
                                        <div className="icon-container py-lg-3 py-3 d-inline-block text-center mx-auto text-center">
                                            <img src={item.img} className="img-fluid w-50" alt={item.name} />
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={7} xs={7}>
                                        <div>
                                            <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, item.name)}</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2} className="text-center">
                                        <FaChevronRight />
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(ProfileOverview));