import { connect } from "react-redux";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { Row, Col } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/home.scss";
import All from "./MyBill/All";
import Recharge from "./MyBill/Recharge";
import Withdrawal from "./MyBill/Withdrawal";

const MyBill = (props) => {
    const [tab, setTab] = useState(1);
    return (
        <>
            <div className="home mybill mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/home/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "My Bill")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex mt-4">
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 1 ? 'active': ""}`} onClick={()=>setTab(1)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "All")}</span>
                    </div>
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 2 ? 'active': ""}`} onClick={()=>setTab(2)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Recharge")}</span>
                    </div>
                    <div className={`tab me-3 px-4 py-3 cursor-pointer ${tab === 3 ? 'active': ""}`} onClick={()=>setTab(3)}>
                        <span className={`font-weight-500 font-16 letter-spacing-05px`}>{translate(props.lang, "Withdrawal")}</span>
                    </div>
                </div>
                
                <div className="mt-4">
                    {tab === 1 ? (
                        <All />
                    ) : tab === 2 ? (
                        <Recharge />
                    ) : <Withdrawal /> }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(MyBill);