import { FaChevronLeft, FaSpinner } from "react-icons/fa";
import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Input } from 'reactstrap'
import { Link, withRouter } from "react-router-dom";
import swal from "sweetalert";
import { api } from "../../../utils/api";
import "../../../css/profile.scss";
import { translate } from "../../../utils/translate";
import { logout } from "../../../store/actions/AuthActions";

const DeleteAccount = (props) => {
    const dispatch = useDispatch();
    const errorsObj = { password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const deleteAcc = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!password) {
            errorMsg.password = "This field is required.";
            check = false;
        }

        setErrors(errorMsg)
        if (check) {
            swal({
                title: translate(props.lang, "Are you sure to delete account?"),
                text: translate(props.lang, "Once deleted, you will not able to recover your data"),
                icon: "warning",
                buttons: [translate(props.lang, "Cancel"), translate(props.lang, "Confirm Delete")],
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        setLoading(true);
                        fetch(api.deleteAccount + '?password=' + password, {
                            method: "POST",
                            headers: new Headers({
                                "Content-Type": "application/json",
                                "Content-Language": props.lang.toLowerCase(),
                                "Authorization": "Bearer " + props.auth.auth.token
                            }),
                        })
                        .then(response => Promise.all([response.status, response.text()]))
                            // .then(response => Promise.all([response.ok, response.text()]))
                            .then(responseJson => {
                                setLoading(false);
                                let result = JSON.parse(responseJson[1]);
                                if (responseJson[0] === 200) {
                                    swal(translate(props.lang, 'Success'), result.message, "success");
                                    setPassword('');
                                } else if (responseJson[0] === 401) {
                                    dispatch(logout(props.history, 'login'));
                                } else {
                                    swal(translate(props.lang, 'Error'), result.message, "error");
                                }
                            }).catch(error => {
                                console.error("error", error);
                            });
                    }
                });

        }
    }

    return (
        <>
            <div className="profile mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link className="back-icon d-inline-block px-3 py-2" to="/profile/settings">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Delete Account")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-black-2 px-4 py-5">
                            <div>
                                <span className={`font-weight-400 font-18 text-white`}>{translate(props.lang, "Delete Account Confirmation")}</span>
                            </div>
                            <div className="mt-2">
                                <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px`}>{translate(props.lang, "Remove this account form your device. After deletion, your account and data cannot be recovered.")}</span>
                            </div>
                            <div className="mt-4 mb-5">
                                <div className={`input-group ${errors.password ? 'errorBorder' : ''}`}>
                                    <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                        <img src={require('../../../images/icons/lock.png').default} className="img-fluid icon" alt="lock" />
                                    </div>
                                    <Input
                                        type={"password"}
                                        name="password"
                                        placeholder={translate(props.lang, "password")}
                                        className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                                <span className="error errorTextRed">{translate(props.lang, errors.password)}</span>
                            </div>
                            <button className={`btn primary-btn btn-block font-weight-400 font-16`} disabled={loading} onClick={() => deleteAcc()}>
                                {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Delete Account")}
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(DeleteAccount));