const ZH = {
    "Datetime": "日期时间",
    "Amount": "金额",
    "Max.": "最多.",
    "Days": "天",
    "deposit": "充值",
    "withdraw": "提现",
    "Type": "交易类型",
    "Loading Data": "加载数据中",
    "Reference no": "参考编号",
    "Time": "日期时间",
    "Contact Us": "联系我们",
    "Status": "状态",
    "pending": "等待处理中",
    "new": "新申请",
    "approved": "申请批准",
    "rejected": "申请拒绝",
    "active": "活跃",
    "Inactive": "不活跃",
    "Ranking": "级别",
    "Downline User": "下线帐号",
    "Loading": "网页加载中",
    "Logout": "登出",
    "Home": "首页",
    "Overview": "概述",
    "My Income": "我的收入",
    "current password": "当前密码",
    "Send Code": "发送验证码",
    "My Bill": "我的帐单",
    "No": "否",
    "Yes": "是",
    "Are you sure to cancel staking?": "您确定要取消质押吗？",
    "Once confirm, you cant revert this action anymore": "一旦确认，您将无法再撤消此操作",
    "Amount cannot less than": "金额不能少于",
    "Amount cannot greater than": "金额不能大于",
    "Total Withdrawal": "提现总额",
    "Total Recharge": "充值总额",
    "Amount must bigger than 0": '金额必须大于 0',
    "Are you sure to delete account?": "您确定要删除帐户吗？",
    "Once deleted, you will not able to recover your data": "一旦删除，您将无法恢复您的数据。",
    "Cancel": "取消",
    "Confirm Delete": "确认删除",
    "Password must at least 6 characters": "密码必须至少 6 个字符",
    "Confirm password need to same with password": "确认密码需与密码相同",
    "Confirm password need to match with new password": "确认密码需要与新密码匹配",
    "Notes:": "须知:",
    "Please Select": "请选择",
    "Withdraw Method": "提现方式",
    "Withdraw To": "Withdraw To",
    "Transfer Wallet Address": "转账钱包地址",
    "Please Enter Wallet Address": "请输入 转账钱包地址",
    "Please Enter Transaction Password": "请输入交易密码",
    "Withdraw Amount": '提款金额',
    "Please Enter amount": "请输入金额",
    "View More": "更多",
    "Withdrawal History": "提款历史",
    "If cancel staking within 1 year will not valid to get 100% interest.": "如果在 1 年内取消质押将无法获得 100% 的利息。",
    "Balance": "余额",
    "confirm": "确认",
    "Please enter staking": "请输入质押",
    "Staking Daily Payout": "质押每日支出",
    "Staking USDT": "质押USDT",
    "Staking Name": "质押名称",
    "Daily Income Rate": "每日收入比率",
    "Cancel Fees": "取消质押手续费",
    "Current Stake Amount": '当前质押数量',
    "Staking Amount": '质押数量',
    "Earn": "赚取",
    "Basic Infomation": "基本资料",
    "Details": "详细内容",
    "investment": "投资",
    "Unit Price": "单位价格",
    "Platform Management Fee": "平台管理费",
    "Total Dividend": "红利总额",
    "Circle": "循环",
    "Rush To Buy Date": "抢购日期",
    "Commission + ROI": '佣金+投资回报率',
    "OTP code has sent to": "验证码已发送至",
    "Please fill in all required fields with valid information.": "请使用有效信息填写所有必填字段。",
    "Invalid Email Format": "电子邮件格式无效",
    "Submit": "提交",
    "thank you!": "谢谢!",
    "Back": "返回",
    "Success": "成功",
    "Error": "错误",
    "Create Personal Account": "创建新帐户",
    "Sign Up": "注册",
    "Already have an account?": "已有帐户？",
    "login now": "立即登录",
    "verfication Code": "验证码",
    "phone number": "手机号码",
    "Register Successful": "成功注册",
    "Forgot Password": "忘记密码",
    "SEND CODE": "发送验证码",
    "Confirm Password": "确认密码",
    "This field is required.": '请填写此项',
    "By clicking “Submit” button, you agree to our Privacy and Terms of Use.": "单击“提交”按钮，即表示您同意我们的隐私和使用条款。",
    "BACK TO LOGIN": "返回登录",
    "RESEND CODE": "重发验证码",
    "Welcome Back": "欢迎回来",
    "Sign up now": "立即注册",
    "Password": "密码",
    "Sign In": "登入",
    "Don’t have an account yet?": "还没有帐户吗？",
    "Forgot Password ?": "忘记密码？",
    "Withdrawal": '提现',
    "All": "全部",
    "Recharge": "充值",
    "Number of Friends": "朋友的数量",
    "Daily Income": "每日收入",
    "Commission": "佣金",
    "Total Commission": "佣金总额",
    "Change Language": "更换语言",
    "Remove this account form your device. After deletion, your account and data cannot be recovered.": "从你的设备上删除这个帐户。删除后，你的帐户和数据将无法恢复。",
    "Delete Account Confirmation": "删除帐户确认",
    "confirm new password": "确认新密码",
    "password": "密码",
    "new password": "新密码",
    "Staking Income": "质押收入",
    "Mining Income": "挖矿收入",
    "Investment of USDT": "投资USDT",
    "Staking": "质押",
    "Investment Amount": "投资金额",
    "Income": "收入",
    "Final Income": "最终收入",
    "Termination Date": "终止日期",
    "Next Settlement": "下次结算",
    "Purchase Date": "购买日期",
    "Mining": '挖矿',
    "Invite 10 friends to rent mining machine": "邀请10位朋友租矿机",
    "Invite 5 friends to rent mining machine": "邀请5位朋友租矿机",
    "Unlock Next Level": "解锁下一个等级",
    "My Mission": "我的任务",
    "Share Link": "分享链接",
    "Invitation Code": "邀请码",
    "Share with Friends": "与朋友分享",
    "Dividend": "红利",
    "Package": "配套",
    "Total Sales": "总销售额",
    "Community Reward": "社区奖励",
    "Copied": "复制成功",
    "referral code": '推荐代码',
    "Upload IC or Passport": "上传身份证/护照",
    "Select Country": "选择国家",
    "Account Name": "银行帐号姓名",
    "Account Number": "银行帐号号码",
    "IC Number": "身份证/护照号码",
    "Bank Name": "银行名称",
    "Update": "更新",
    "username": '帐号',
    "email": "电子邮件",
    "Balance of USDT": "USDT余额",
    "User Management": "用户管理",
    "My Referral Code": "我的推荐代码",
    "Customer Support": "客服",
    "Profile": '我的资料',
    "Settings": "设置",
    "Bank Information": '银行信息',
    "Opps !": "错误 !",
    "Something went terribly wrong, please try again": "出现严重错误，请重试",
    "Retry": "重试",
    "Connection Failed": "连接失败",
    "Your internet connection is lost": "您的互联网连接丢失",
    "No Results Found": "没有数据",
    "There’s nothing for now": '现在没有找到记录',
    "Login Password": '登录密码',
    "Transaction Password": "交易密码",
    "Delete Account": "删除帐户",

    "email verfication Code": "邮箱验证码",
    "Dial Code": "拨号代码",
    "Phone No.": '手机号码',
    "phone verfication Code": '手机验证码',
}

export default ZH;