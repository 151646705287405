let en = null;
let zh = null;
let zh_tw = null;
let vi = null;
let ja = null;
let kr = null;

export let translate = (lang, key) => {
    switch (lang) {
        case "EN":
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }

        case "ZH":
            if (zh === null) {
                zh = require("../language/zh_cn").default;
            }
            if (zh[key]) {
                return zh[key];
            } else {
                return key;
            }
        case "ZH_TW":
            if (zh_tw === null) {
                zh_tw = require("../language/zh_tw").default;
            }
            if (zh_tw[key]) {
                return zh_tw[key];
            } else {
                return key;
            }
        case "VI":
            if (vi === null) {
                vi = require("../language/vi").default;
            }
            if (vi[key]) {
                return vi[key];
            } else {
                return key;
            }
        case "JA":
            if (ja === null) {
                ja = require("../language/ja").default;
            }
            if (ja[key]) {
                return ja[key];
            } else {
                return key;
            }
        case "KR":
            if (kr === null) {
                kr = require("../language/kr").default;
            }
            if (kr[key]) {
                return kr[key];
            } else {
                return key;
            }
        default:
            if (en === null) {
                en = require("../language/en").default;
            }
            if (en[key]) {
                return en[key];
            } else {
                return key;
            }
    }
};