import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { translate } from '../../../utils/translate';
import { minTwoDigits } from "../../../utils/function";

const calculateTimeLeft = (endTime) => {
    let date1 = new Date(endTime);
    let date2 = new Date();
   
    let seconds = Math.floor((date1 - (date2))/1000);
    let minutes = Math.floor(seconds/60);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
   
    hours = hours-(days*24);
    minutes = minutes-(days*24*60)-(hours*60);
    seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
    const difference = +date1 - +date2;
   
 
    if (difference > 0) {
       let timeLeft = {
          days: days ? days : "00", 
          hours: hours ? minTwoDigits(hours) : "00", 
          minutes: minutes ? minTwoDigits(minutes) : "00", 
          seconds: seconds ? minTwoDigits(seconds): "00", 
       };
       return timeLeft;
    }else{
        let timeLeft = null
        return timeLeft;
    }
 
    
};

const Countdown = (props) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(props.data));
    useEffect(() => {
        setTimeout(() => {
           setTimeLeft(calculateTimeLeft(props.data));
        }, 1000);
    });

    return(
        <div className={`settlementDate px-2 py-3`}>
            <span className={`font-weight-400 font-13 letter-spacing-05px`}>
                {timeLeft ? (
                    <span>{timeLeft.days} {translate(props.lang, 'Days')} {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</span>
                ) : <span>00:00:00</span>}
                {/* <span id="days">{timeLeft.days}</span>{translate(props.lang, 'Days')}
                <span id="hours" className='ms-2'>{timeLeft.hours}</span>:
                <span id="minutes">{timeLeft.minutes}</span>:
                <span id="seconds">{timeLeft.seconds}</span> */}
            </span>
        </div>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Countdown);