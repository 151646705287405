/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { translate } from "../../../utils/translate";
import { FaHome } from "react-icons/fa";
import SidebarMenu from "../../../utils/menu";
import "../../../css/sidebar.scss";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const location = useLocation();
  const history = useHistory();
  const [path, setPath] = useState("");
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
  }, []);

  const toggleMenuFunc = (forceClose = false) => {
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    // console.log(aaa.classList.contains("menu-toggle"), 'check');
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      return;
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      return;
    } else {
      aaabackdrop.classList.remove('d-none');
      aaa.classList.add("menu-toggle");
      return
    }
  }

  let scrollPosition = useScrollPosition();
  /// Path
  useEffect(() => {
      setPath(location.pathname);
  }, [location]);
 
  const closeMenu = (hasChildren) => {
    if(!hasChildren){
      var element = document.getElementById("main-wrapper");
      element.classList.remove("menu-toggle");
    }
  }
  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {
            SidebarMenu.map((parent, i) => (
              <li key={i} className={`mx-4 px-0 mb-2 ${parent.allPath.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
                <Link className={`ai-icon py-3 px-4 w-100`} to={parent.pathName === "#" ? "#" : parent.pathName} onClick={()=>closeMenu(parent.children.length > 0 ? true : false )}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={parent.inactiveImg} className="icon-inactive" alt={parent.menu} />
                    <img src={parent.activeImg} className="icon-active" alt={parent.menu} />
                  </div>
                  <span className={`ms-4 font-weight-400 font-16 text-capitalize ms-2 menu-text`}>
                    {translate(props.lang, parent.menu)}
                  </span>
                </Link>
                {parent.children.length > 0 ? (
                  <ul className={`submenu ${parent.allPath.includes(path) ? "mm-show": ""}`}>
                    {parent.children.map((child, key) => (
                      <li key={key} className={`${child.pathName === path  ? "mm-active menu-active" : "menu-inactive"}`} onClick={()=>closeMenu(false)}>
                        <Link to={child.pathName}>
                          <span className={`font-weight-400 font-14 text-capitalize child-menu-text`}>{translate(props.lang, child.menu)}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))
          }
        </MM>
      </PerfectScrollbar>
    </div>
  );
};
const mapStateToProps = state => {
  const { i18n, theme } = state;
  return {
    theme: theme.theme,
    lang: i18n.lang,
  }
}
export default connect(mapStateToProps)(SideBar);
