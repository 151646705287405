import { FaChevronLeft } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Row, Col } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { Link } from "react-router-dom";

const lang = [
    { name: 'English', code: 'EN' },
    { name: '中文（简体）', code: 'ZH' },
    // { name: 'Japanese', code: 'JA' },
    // { name: 'Korean', code: 'KR' },
    // { name: 'Vietnamese', code: 'VI' },
]


const Language = (props) => {
    const dispatch = useDispatch();
    const changeLanguage = (language) => {
		dispatch({
			type: 'UPDATE_LANGUAGE',
			lang: language
		});
		localStorage.setItem('starcloud-lang', language);
	}
   
    return (
        <>
            <div className="profile language mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link className="back-icon d-inline-block px-3 py-2" to="/profile/settings">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Change Language")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    {lang.map((item, i) => (
                        <Col lg={6} md={6} sm={12} xs={12} key={i}>
                            <div className="card card-black-2 px-4 py-5 cursor-pointer" onClick={()=>changeLanguage(item.code)}>
                                <Row>
                                    <Col lg={10} md={10} sm={10} xs={10}>
                                        <div>
                                            <span className="font-weight-400 font-16 text-white letter-spacing-05px">{item.name}</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={2} sm={2} xs={2}>
                                        <div className="outerDot">
                                        {props.lang === item.code ? (
                                            <div className="innerDot"></div>
                                        ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Language);