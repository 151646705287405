import { useEffect, useState } from "react";
import { FaChevronLeft, FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import swal from "sweetalert";
import { Row, Col, Input } from 'reactstrap'
import { translate } from "../../../utils/translate";
import "../../../css/profile.scss";
import { api } from "../../../utils/api";
import { replaceImgUrl } from "../../../utils/function";
import { logout } from "../../../store/actions/AuthActions";
import Loadingv3 from "../../components/Loading/Loadingv4";

const BankInformation = (props) => {
    const dispatch = useDispatch();
    const [loadingData, setLoadingData] = useState(true);
    const errorsObj = { country: '', bankName: '', accountName: '', accountNo: '', icNo: '', transactionPassword: '', icpassport: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [submitHistory, setSubmitHistory] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [countryOption, setCountryOption] = useState([]);
    const [status, setStatus] = useState('new');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        country: '',
        bankName: '',
        accountName: '',
        accountNo: '',
        icNo: '',
        transactionPassword: '',
        icpassport: null,
    });
    const [img, setImg] = useState({
        document: null
    });

    useEffect(() => {
        getBankInfo();
    }, [props.auth.auth.token])

    const getBankInfo = () => {
        fetch(api.getBankInfo, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoadingData(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setSubmitHistory(result);
                    const newFormData = { ...formData };
                    newFormData.country = result.country[0].key;
                    newFormData.bankName = result.bankName;
                    newFormData.accountName = result.accName;
                    newFormData.accountNo = result.accNumber;
                    newFormData.icNo = result.icNumber;
                    const imgObj = urlToObject(replaceImgUrl(result.idPicture));
                    // const imgObj = result.idPicture;
                    newFormData.icpassport = imgObj;
                    setFormData(newFormData);
                    const newImg = { ...img };
                    newImg.document = replaceImgUrl(result.idPicture);
                    setImg(newImg);

                    setStatus(result.bankVerifyStatus ? result.bankVerifyStatus.toLowerCase() : "New");

                    setCountryOption(result.country);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    // const urlToObject = async (url) => {
    //     // console.log("URL", url);
    //     const response = await fetch(url);
    //     const blob = await response.blob();
    //     const file = new File([blob], 'image.png', { type: blob.type });
    //     return file;
    // }
    const urlToObject = async (url) => {
        let filename = url.substring(url.lastIndexOf('/') + 1);
        const response = await fetch(url)

        const blob = await response.blob();
        const file = new File([blob], filename, { type: 'image/' + filename.split(".").pop() });

        return file;
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e.target;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const uploadImage = (e) => {
        if (e.target.files[0]) {
            const newFormData = { ...formData };
            const newImg = { ...img };
            newFormData['icpassport'] = e.target.files[0];
            newImg.document = URL.createObjectURL(e.target.files[0]);
            console.log("e.target.files[0]", e.target.files[0])
            setFormData(newFormData);
            setImg(newImg);
        }
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!formData.country) {
            errorMsg.country = 'This field is required.';
            check = false;
        }
        if (!formData.bankName) {
            errorMsg.bankName = 'This field is required.';
            check = false;
        }
        if (!formData.accountName) {
            errorMsg.accountName = 'This field is required.';
            check = false;
        }
        if (!formData.accountNo) {
            errorMsg.accountNo = 'This field is required.';
            check = false;
        }
        if (!formData.icNo) {
            errorMsg.icNo = 'This field is required.';
            check = false;
        }
        if (!formData.transactionPassword) {
            errorMsg.transactionPassword = 'This field is required.';
            check = false;
        }
        if (!formData.icpassport) {
            errorMsg.icpassport = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            const form = new FormData();
            form.append("country", formData.country);
            form.append("bankName", formData.bankName);
            form.append("accountName", formData.accountName);
            form.append("accountNumber", formData.accountNo);
            form.append("icNumber", formData.icNo);
            form.append("transactionPassword", formData.transactionPassword);
            form.append("fileUpload", formData.icpassport);
            fetch(api.submitBankInfo, {
                method: "POST",
                headers: new Headers({
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: form
            })
                .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        if(result.status === "fail"){
                            swal(translate(props.lang, 'Error'), result.messages, "error");
                        }else{
                            swal(translate(props.lang, 'Success'), result.messages, "success");
                            getBankInfo();
                        }
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        } else {
            swal(translate(props.lang, 'Error'), translate(props.lang, 'Please fill in all required fields with valid information.'), "error");
        }
    }


    return (
        <>
            <div className="profile bank-info mt-4 mt-lg-0 mt-md-2">
                <div>
                    <Row className="justify-content-center align-items-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <Link to="/profile/overview" className="back-icon d-inline-block px-3 py-2">
                                <FaChevronLeft />
                            </Link>
                        </Col>
                        <Col lg={11} md={11} sm={10} xs={10} className="text-start">
                            <div className="ms-md-1 ms-lg-1">
                                <span className={`font-weight-500 font-20 letter-spacing-003em text-white`}>{translate(props.lang, "Bank Information")}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row className="justify-content-start align-items-center mt-4">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="card card-transparent-dot px-4 py-3">
                            <div className="d-flex w-100 align-items-center">
                                <div>
                                    <img src={require('../../../images/profile/checked-pending.png').default} className="img-fluid w-50" alt="check" />
                                </div>
                                <div>
                                    <span className={`font-weight-400 font-14 letter-spacing-05px status ${status.toLowerCase()}`}>{translate(props.lang, status.toLocaleLowerCase())}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                {loadingData ? (
                    <Row className="justify-content-start align-items-center mt-2">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="card card-black-2 px-5 py-5">
                                <Loadingv3 />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="justify-content-start align-items-center mt-2">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="card card-black-2 px-5 py-5">
                                <Row className="align-items-start">
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.country ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                    <img src={require('../../../images/profile/country.png').default} className="img-fluid icon" alt="icon" />
                                                </div>

                                                <Input
                                                    type="select"
                                                    name="country"
                                                    className="form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px"
                                                    value={formData.country}
                                                    onChange={(e) => handleOptionSelected(e, "country")}
                                                    disabled={status === 'pending' || status === 'approved'}
                                                >
                                                    <option value="">{translate(props.lang, "Select Country")}</option>
                                                    {countryOption.map((item, i) => (
                                                        <option value={item.key} key={i}>{item.value}</option>

                                                    ))}
                                                </Input>

                                            </div>
                                            <span className="error errorTextRed">{translate(props.lang, errors.country)}</span>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.bankName ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                    <img src={require('../../../images/profile/bank.png').default} className="img-fluid icon" alt="icon" />
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="bankName"
                                                    placeholder={translate(props.lang, "Bank Name")}
                                                    className={`form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px`}
                                                    value={formData.bankName}
                                                    onChange={e => handleAddFormChange(e)}
                                                    autoComplete="off"
                                                    disabled={status === 'pending' || status === 'approved'}
                                                />
                                            </div>
                                            <span className="error errorTextRed">{translate(props.lang, errors.bankName)}</span>
                                        </div>

                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.accountName ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                    <img src={require('../../../images/profile/account-name.png').default} className="img-fluid icon" alt="icon" />
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="accountName"
                                                    placeholder={translate(props.lang, "Account Name")}
                                                    className={`form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px`}
                                                    value={formData.accountName}
                                                    onChange={e => handleAddFormChange(e)}
                                                    autoComplete="off"
                                                    disabled={status === 'pending' || status === 'approved'}
                                                />
                                            </div>
                                            <span className="error errorTextRed">{translate(props.lang, errors.accountName)}</span>
                                        </div>

                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.accountNo ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                    <img src={require('../../../images/profile/account-no.png').default} className="img-fluid icon" alt="icon" />
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="accountNo"
                                                    placeholder={translate(props.lang, "Account Number")}
                                                    className={`form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px`}
                                                    value={formData.accountNo}
                                                    onChange={e => handleAddFormChange(e)}
                                                    autoComplete="off"
                                                    disabled={status === 'pending' || status === 'approved'}
                                                />
                                            </div>
                                            <span className="error errorTextRed">{translate(props.lang, errors.accountNo)}</span>
                                        </div>

                                    </Col>
                                    <Col lg={4} md={4} sm={12} xs={12} className="mb-3">
                                        <div>
                                            <div className={`input-group ${errors.icNo ? 'errorBorder' : ''}`}>
                                                <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                    <img src={require('../../../images/profile/ic.png').default} className="img-fluid icon" alt="icon" />
                                                </div>
                                                <Input
                                                    type="text"
                                                    name="icNo"
                                                    placeholder={translate(props.lang, "IC Number")}
                                                    className={`form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px`}
                                                    value={formData.icNo}
                                                    onChange={e => handleAddFormChange(e)}
                                                    autoComplete="off"
                                                    disabled={status === 'pending' || status === 'approved'}
                                                />
                                            </div>
                                            <span className="error errorTextRed">{translate(props.lang, errors.icNo)}</span>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                        <div className={`input-group ${errors.accountName ? 'errorBorder' : ''} py-5`}>
                                            <div className="w-100 text-center">

                                                <label htmlFor="uploadIc">
                                                    <div className="text-center">
                                                        {img.document ? (
                                                            <div className="py-2">
                                                                <img src={img.document} className="img-fluid icon" alt="document" />
                                                            </div>
                                                        ) : (
                                                            <img src={require('../../../images/profile/icpassport.png').default} className="img-fluid icon" alt="ic passport" />
                                                        )}
                                                    </div>
                                                    <div className="mt-4">
                                                        <span className={`font-weight-400 font-14 secondary-text letter-spacing-05px`}>{translate(props.lang, "Upload IC or Passport")}</span>
                                                    </div>
                                                </label>
                                                <input type="file" id="uploadIc" className=" d-none" onChange={(e) => uploadImage(e)} disabled={status === 'pending' || status === 'approved'} />

                                            </div>
                                        </div>
                                        <span className="error errorTextRed">{translate(props.lang, errors.icpassport)}</span>
                                    </Col>
                                    {status === 'pending' || status === 'approved' ? null : (
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                            <div>
                                                <div className={`input-group ${errors.transactionPassword ? 'errorBorder' : ''}`}>
                                                    <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                                        <img src={require('../../../images/icons/lock.png').default} className="img-fluid icon" alt="icon" />
                                                    </div>
                                                    <Input
                                                        type={showPassword ? "text" : "password"}
                                                        name="transactionPassword"
                                                        placeholder={translate(props.lang, "Transaction Password")}
                                                        className={`form-control input-transparent font-weight-400 font-14 secondary-text letter-spacing-05px`}
                                                        value={formData.transactionPassword}
                                                        autoComplete="off"
                                                        onChange={e => handleAddFormChange(e)}
                                                    />
                                                    <div className='d-flex align-items-center justify-content-center transparent-bg' onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? (<img src={require('../../../images/icons/hide.png').default} className="img-fluid icon" />) : (<img src={require('../../../images/icons/show.png').default} className="img-fluid icon" />)}
                                                    </div>
                                                </div>
                                                <span className="error errorTextRed">{translate(props.lang, errors.transactionPassword)}</span>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                {status === 'pending' || status === 'approved' ? null : (
                                    <button className={`mt-5 btn primary-btn btn-block font-weight-400 font-16`} disabled={loading} onClick={() => submitForm()}>
                                        {loading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Update")}
                                    </button>
                                )}
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(BankInformation));