import { FaChevronRight, FaSpinner } from 'react-icons/fa';
import { connect, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import { Row, Col } from 'reactstrap';
import { api } from '../../../../utils/api';
import { calculatePercent, replaceImgUrl } from '../../../../utils/function';
import { translate } from "../../../../utils/translate";
import { logout } from '../../../../store/actions/AuthActions';
import Loadingv3 from '../../../components/Loading/Loadingv4';

const Mining = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const [miningData, setMiningData] = useState({
        bannerUrl: [],
        miningPackageList: [],
        miningPackageMaxProfit: [],
    });
    let errorsObj = { password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [passwordModal, setPasswordModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    useEffect(() => {
        getInfo();
    }, [props.auth.auth.token])

    const getInfo = () => {
        setLoading(true)
        fetch(api.getMiningPackageList, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Content-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.token
            }),
        })
            .then(response => Promise.all([response.status, response.text()]))
            // .then(response => Promise.all([response.ok, response.text()]))
            .then(responseJson => {
                setLoading(false);
                let result = JSON.parse(responseJson[1]);
                if (responseJson[0] === 200) {
                    setMiningData(result);
                } else if (responseJson[0] === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, 'Error'), result.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const openModal = (id) => {
        setSelectedPackage(id);
        setPasswordModal(true);
    }

    const closeModal = () => {
        setPasswordModal(false);
        setSelectedPackage(null);
        setPassword('');
    }

    const submitPassword = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        if (!password) {
            errorMsg.password = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg)
        if (check) {
            setPurchaseLoading(true);
            let data = {
                "productId": selectedPackage,
                "transactionPassword": password
            }
            fetch(api.purchaseMiningPackage, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Content-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.token
                }),
                body: JSON.stringify(data)
            })
                .then(response => Promise.all([response.status, response.text()]))
                // .then(response => Promise.all([response.ok, response.text()]))
                .then(responseJson => {
                    setPurchaseLoading(false);
                    let result = JSON.parse(responseJson[1]);
                    if (responseJson[0] === 200) {
                        swal(result.message)
                            .then((value) => {
                                closeModal();
                            });
                    } else if (responseJson[0] === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, 'Error'), result.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }

    }

    return (
        <>
            <div className="home overview">

                {loading ? (
                    <div className='card qr-card-bg py-4 px-3 mt-5'>
                        <Loadingv3 />
                    </div>) : (
                    <Row className='mt-5'>
                        {miningData.miningPackageList.map((item, i) => (
                            <Col lg={3} md={6} sm={6} xs={12} key={i}>
                                <div className='card qr-card-bg py-4 px-3'>
                                    <Link className='d-flex align-items-center w-100' to={"/home/package/" + item.productId}>
                                        <img src={replaceImgUrl(item.imageUrl)} className="w-25 mining-package" alt="package" />
                                        <div className='w-50'>
                                            <span className='ms-3 font-weight-500 font-20 text-white letter-spacing-05px text-uppercase'>{item.productName}</span>
                                        </div>
                                        <div className='w-25 text-end'>
                                            <FaChevronRight color="#ffffff" />
                                        </div>
                                    </Link>
                                    <hr />
                                    <div className='mb-3'>
                                        <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1`}>{translate(props.lang, "Unit Price")}</span>
                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block`}>{item.unitPrice}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1`}>{translate(props.lang, "Daily Income")}</span>
                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block`}>{item.dailyIncome}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1`}>{translate(props.lang, "Total Dividend")}</span>
                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block`}>{item.totalDividend}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1`}>{translate(props.lang, "Circle")}</span>
                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block`}>{item.circle}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <span className={`font-weight-400 font-13 secondary-text letter-spacing-05px d-block mb-1`}>{translate(props.lang, "Rush To Buy Date")}</span>
                                        <span className={`font-weight-400 font-13 text-white letter-spacing-05px d-block`}>{item.rushToBuyDate}</span>
                                    </div>
                                    <div onClick={() => openModal(item.productId)} className={`btn primary-btn btn-block font-weight-400 font-16 text-capitalize`}>
                                        {translate(props.lang, "investment")}
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )}

                <Row className='mt-4'>
                    <Col lg={12} md={12} sm={12} xs={12} >
                        <div className="card qr-card-bg px-4 py-4">
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div>
                                        <span className={`font-weight-400 font-18 letter-spacing-05px text-white`}>{translate(props.lang, 'Commission + ROI')}</span>
                                    </div>
                                    {loading ? (<Loadingv3 />) : (
                                        miningData.miningPackageMaxProfit.length > 0 ? (
                                            miningData.miningPackageMaxProfit.map((item, i) => (
                                                <div className="d-flex w-100 align-items-center mt-3" key={i}>
                                                    <div className="w-75">
                                                        <div className="progress2 progress-moved position-relative">
                                                            <div className={`progress-bar2 progress-with-text ${calculatePercent(item.totalProfit, item.maximumProfit)}`} >
                                                            </div>
                                                            <span className={`font-weight-300 font-8 letter-spacing-003em text-white percentage`}>{item.totalProfit + "/" + item.maximumProfit}</span>
                                                        </div>
                                                    </div>
                                                    <div className="text-end w-25">
                                                        <span className={`font-weight-500 font-12 letter-spacing-05px primary-text`}>{item.maximumProfitWithUsdtWord}</span>
                                                    </div>
                                                </div>
                                            ))

                                        ) : null

                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal className="fade passwordModal" show={passwordModal} centered>
                <Modal.Body className="py-5 px-4">
                    <div>
                        <span className={`font-weight-400 font-24 letter-spacing-003em text-white text-capitalize`}>{translate(props.lang, "Transaction Password")}</span>
                    </div>
                    <div className="mt-4">
                        <div className={`input-group ${errors.password ? 'errorBorder' : ''}`}>
                            <div className='d-flex align-items-center justify-content-center transparent-bg'>
                                <img src={require('../../../../images/icons/lock.png').default} className="img-fluid w-50" />
                            </div>
                            <input
                                type={'password'}
                                className={`form-control input-transparent font-weight-400 font-14 text-white letter-spacing-05px`}
                                value={password}
                                placeholder={translate(props.lang, "Password")}
                                onChange={(e) =>
                                    setPassword(e.target.value)
                                }
                            />
                        </div>
                        <span className="error errorTextRed">{translate(props.lang, errors.password)}</span>
                    </div>
                    <Row className="justify-content-center align-items-center mt-4">
                        <Col>
                            <button className={`btn cancel-btn btn-block font-weight-400 font-16`} onClick={() => closeModal()} disabled={purchaseLoading}>
                                {translate(props.lang, "Cancel")}
                            </button>
                        </Col>
                        <Col>
                            <button className={`btn primary-btn btn-block font-weight-400 font-16`} onClick={() => submitPassword()} disabled={purchaseLoading}>
                                {purchaseLoading ? <FaSpinner className='fa-spin' /> : translate(props.lang, "Submit")}
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}
export default withRouter(connect(mapStateToProps)(Mining));